.modal_bg{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($color: $FULL_BLACK, $alpha: .3);
	display: none;
	z-index: 5;
	.question_modal{
		right: -100%;
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type=number] {
			-moz-appearance: textfield;
		}
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		background-color: $WHITE;
		margin: 0 auto;
		width: 550px;
		padding: 40px;
		.modal_head{
			display: flex;
			justify-content: flex-end;
			margin-bottom: 30px;
			p.headline{
				@include font(28, 40, 400);
				margin-right: auto;
			}
			a.del{
				margin-right: unset;
				width: 40px;
				background-size: 20px;
			}
		}
		.modal_body{
			&[data-stage]:not([data-stage='1']){
				display: none
			}
			.input_holder{
				p.key{
					@include font(16, 22, 400);
					&.require{
						padding: 0 0 0 10px;
						position: relative;
						@include font(16, 22, 400);
						color: $T_GREY82;
						margin-bottom: 5px;
						&:before{
							content: '*';
							position: absolute;
							top: 0;
							left: 0;
							color: $RED;
						}
					}
				}
				textarea,
				input{
					border: 1px solid $BG_GREY;
					// border: 1px solid $BLACK;
					width: 100%;
					padding: 0 15px;
					@include font(16, 30, 400);
					border-radius: 5px;
					margin-bottom: 15px;
				}
				textarea{
					height: 100px;
					@include font(16, 20, 400);
				}
			}
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: unset;
			}
		}
		.bottom_controls{
			display: flex;
			justify-content: flex-end;
			a.btn{
				@include font(20, 45, 400);
				padding: 0 15px;
				&.form_submit:not([disabled]){
					background-color: $RED;
					color: $WHITE;
				}
			}

		}
	}
}

@include mobile {
	@import "modal_adp";
}