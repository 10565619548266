.menu{
	background-color: $BG_WHITE;
	padding: 25px 0;
	ul{
		display: flex;
		margin: 0 auto;
		max-width: 1280px;
		justify-content: space-between;
		position: relative;
		li{
			margin-right: 25px;
			position: relative;
			&:last-child{
				margin-right: unset;
			}
			a.menu_item{
				display: block;
				@include font(20, 35, 400);
				color: $FULL_BLACK;
				transition: color .25s;
				&.active{
					color: var(--promo-color);
				}
				&:hover:not(.btn_like){
					color: var(--promo-color);
				}
				&.btn_like{
					padding: 0 10px;
					background-color: var(--promo-color);
					color: $WHITE;
				}
				&.extend{
					padding: 0 20px 0 0;
					position: relative;
					&::after{
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						width: 10px;
						height: 7px;
						background-image: url(../img/icon/ic-toggle_red.svg);
						background-position: center;
						background-size: 80%;
						background-repeat: no-repeat;
					}
				}
			}
		}
		&.submenu{
			flex-direction: column;
			position: absolute;
			background-color: $WHITE;
			width: 250px;
			padding: 10px;
			border-radius: 5px;
			box-shadow: 5px 5px 15px -5px rgba(0,0,0,0.5);
			overflow: hidden;
			transition: opacity .15s;
			z-index: -1;
			opacity: 0;
			li{
				@include font(20, 35, 400);
				transition: color .25s;
				&:hover{
					color: var(--promo-color);
				}
			}
		}
		li:hover{
			ul.submenu{
				z-index: 2;
				opacity: 1;
			}
		}
	}
	&.en{
		
		ul{
			// li{
			// 	a.menu_item{
			// 		@include font(17, 35, 400);
			// 	}
			// }
			&.submenu{
				li{
					@include font(17, 35, 400);
				}
			}
		}
	}
}
@include mobile {
	@import "menu_adp";
}