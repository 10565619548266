@import "normalize";
@import "fonts";
@import "func";
/* BACKGROUND */
$BG_WHITE: #FAFAFA;
$BG_BLACK: #171414;
$BG_GREY: #EFEFEF;
/* LINES */
$LN_GREY: #c4c4c4;
/* TEXT */
$T_RED: #D10B0B;
$T_BLACK: #171414;
$T_GREYB3: #B3B3B3; // _ 
$T_GREYBD: #BDBDBD; // 3
$T_GREYB5: #B5B5B5; // 3
$T_GREY82: #828282;
$T_YELLOW: #F2C94C;
/* BASE */
$FULL_BLACK: #000000;
$BLACK: #050505;
$WHITE: #ffffff;
$RED: #FF0000;
/* BORDER */
/* ALPHA */
$FBLK_25: rgba(0,0,0, .25);
/* REPLACE DEFAULT */
input,
textarea,
button,
select,
a {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
*:focus {
	outline: none;
}
*,
*:after,
*::before {
	box-sizing: border-box;
}
.clearfix::after{
	content: '';
	clear: both;
	display: block;
	height: 0;
}
/* BODY */
body {
	height: 100%;
	font-family: 'FavoritPro';
	color: $FULL_BLACK;
	position: relative;
	min-width: 1440px;
	background: $BG_WHITE;
	&.noscroll{
		overflow: hidden;
	}
}
.btn{
	display: block;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	transition: all .25s;
	&.social{
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background-size: cover;
		&.fb{
			background-image: url(../img/icon/social/facebook.svg);
		}
		&.yt{
			background-image: url(../img/icon/social/youtube.svg);
		}
		&.insta{
			background-image: url(../img/icon/social/instagram.svg);
		}
	}
	&[disabled]{
		pointer-events: none;
		background-color: $LN_GREY;
		color: $WHITE;
	}
}
.ico{
	display: block;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	&.star{
		background-image: url(../img/icon/ic-star.svg);
		&.white{
			background-image: url(../img/icon/ic-star_white.svg);
		}
		&.un{
			background-image: url(../img/icon/ic-star_filled.svg);
		}
	}
	&.login{
		background-image: url(../img/icon/ic-user.svg);
	}
	&.cart{
		background-image: url(../img/icon/ic-cart.svg);
	}
	&.buy{
		background-image: url(../img/icon/ic-cart_plus.svg);
		width: 145px;
		height: 52px;
		position: relative;
		&:after{
			content: attr(data-buy);
			position: absolute;
			left: 60px;
		}
		&.un{
			&:after{
				content: attr(data-un);
				left: 47px;
				position: absolute;
			}	
		}
	}
	&.tel{
		background-image: url(../img/icon/ic-phone.svg);
	}
	&.del, &.close{
		background-image: url(../img/icon/ic-close.svg);
	}
	&.toggle{
		background-image: url(../img/icon/ic-toggle_down.svg);
	}
}
.logo{
	width: 250px;
	height: 50px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
@include mobile {
	.btn{
		&.social{
			border-radius: 50%;
			width: vw-mob(40px);
			height: vw-mob(40px);
			&.fb{
				background-image: url(../img/icon/social/facebook.svg);
			}
			&.yt{
				background-image: url(../img/icon/social/youtube.svg);
			}
			&.insta{
				background-image: url(../img/icon/social/instagram.svg);
			}
		}
	}
}