/* BODY */
body {
	min-width: unset;
}
.breadcrumbs{
	padding: 10px 40px;
	background-color: $WHITE;
	ul.main_nav{
		display: flex;
		max-width: 1600px;
		margin: 0 auto;
		li.nav_item{
			position: relative;
			padding: 0 35px 0 0;
			@include font(12, 16, 400);
			color: $T_GREYBD;
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
				background-image: url(../img/icon/ic-arrow2_grey.svg);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: 6px;
				height: 12px;
			}
			&.active{
				color: $FULL_BLACK;
			}
			&:last-child{
				padding: unset;
				&:after{
					content: none;
				}
			}
		}
	}
}
.sales{
	position: relative;
	max-height: 685px;
	padding: 40px;
	margin-bottom: 100px;
	.sales_head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;
		h2.headline{
			@include font(48, 64, 400);
			text-transform: uppercase;
			color: $RED;
		}
		.slide_controls{
			display: flex;
			justify-content: space-between;
			width: 110px;
			a{
				width: 50px;
				height: 50px;
				background-color: $T_BLACK;
				// &.disabled{
				&.swiper-button-disabled{
					background-color: $LN_GREY;
				}
				background-image: url(../img/icon/ic-arrow2_white.svg);
				background-size: 40%;
				background-position: center;
				&.prev{
					transform: rotate(180deg);
				}
			}
		}
	}
	.sales_tail{
		position: absolute;
		width: 385px;
		top: 685px;
		left: calc(50% - 180px);
		display: flex;
		justify-content: center;
	}
	.sales_body{
		padding: 20px 20px;
		display: flex;
		justify-content: space-between;
	}
}
a.arrow{
	position: relative;
	padding: 0 vw-mob(25px) 0 0;
	@include vw-font(14, 16, 400);
	color: $RED;
	&:after{
		content: '';
		width: vw-mob(18px);
		height: vw-mob(16px);
	}
}
.item{
	display: block;
	position: relative;
	background-color: $WHITE;
	width: 325px;
	height: 450px;
	margin-right: 20px;
	&:last-child{
		margin-right: unset;
	}
	padding: 20px;
	.flags{
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: baseline;
		span{
			padding: 0 5px;
			border-radius: 65px;
			color: $WHITE;
			margin-bottom: 10px;
			height: 25px;
			@include font(14, 28, 400);
			text-transform: uppercase;
			&:last-child{
				margin-bottom: unset;
			}
			&.top{
				background-color: $T_YELLOW;
			}
			&.sale{
				background-color: $RED;
			}
		}
	}
	.rate{
		position: absolute;
		right: 20px;
		span{
			width: 25px;
			height: 25px;
		}
	}
	.poster{
		width: 100%;
		height: 250px;
		background-image: var(--i-img);
		background-repeat: no-repeat;
		background-size: auto 90%;
		background-position: center;
		margin-bottom: 20px;
	}
	.info{
		margin-bottom: 30px;
		a.key{
			@include font(20, 26, 400);
			display: block
		}
		a.sub{
			@include font(16, 21, 400);
			color: $T_GREYBD;
		}
	}
	.order{
		display: flex;
		justify-content: space-between;
		p.price{
			position: relative;
			@include font(20, 26, 400);
			&[data-prev]:not([data-prev=""]){
				padding: 20px 0 0 0;
				&:after{
					content: attr(data-prev);
					position: absolute;
					@include font(16, 18, 400);
					text-decoration: line-through;
					color: $T_GREYBD;
					top: 0;
					left: 0;
				}
			}
		}
		a.buy{
			background-color: $BG_GREY;
			padding: 0 15px 0 60px;
			background-size: 25px auto;
			background-position: 18px center;
			&.un{
				background-position: 15px center;
			}
			&:after{
				top: 0;
				@include font(20, 52, 400);
			}
		}
	}
	.extra{
		visibility: hidden;
	}
	&:hover{
		z-index: 1;
		box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .28);
		.extra{
			visibility: visible;
			width: calc(100% + 40px);
			box-shadow: 0 25px 20px 0 rgba(0, 0, 0, .28);
			left: -20px;
			display: block;
			position: relative;
			.border{
				width: 100%;
				border-top: 1px solid $LN_GREY;
				margin: 30px 0 20px 0;
			}
			background-color: $WHITE;
			padding: 0 20px 20px 20px;
			display: flex;
			flex-wrap: wrap;
			p.key{
				width: 50%;
				@include font(16, 21, 400);
				text-align: end;
				margin-bottom: 10px;
				&:nth-last-child(-n+2){
					margin-bottom: unset;
				}
				&.alt{
					color: $T_GREYBD;
					text-align: unset;
				}
			}
		}
	}
}
.sales_body{
	.item{
		&:hover{
			height: auto;
			.extra{
				box-shadow: unset;
				padding: 0 20px;
			}
		}
	}
}
.swiper{
	.swiper-pagination{
		bottom: vw-mob(60px);
		.swiper-pagination-bullet{
			width: vw-mob(10px);
			height: vw-mob(10px);
			margin: 0 vw-mob(10px);
		}
	}
}
.social_resp,
.farming_poster,
.innovations{
	.box_wrap{
		.box{
			padding: vw-mob(20px);
			p.item_title{
				@include vw-font(18, 20, 400);
				margin-bottom: vw-mob(10px);
			}
			.key{
				p {
					@include vw-font(14, 18, 400);
				}
			}
			&::after{
				width: vw-mob(20px);
				height: vw-mob(20px);
				bottom: vw-mob(20px);
				left: vw-mob(20px);
			}
		}
	}
	&.innovations{
		padding: 0 20px;
		margin-bottom: 100px;
		h2.item_title{
			@include font(48, 64, 400);
			text-transform: uppercase;
			text-align: center;
			color: $RED;
			margin-bottom: 40px;
		}
		.key{
			margin-bottom: 70px;
			p{
				@include font(28, 37, 400);
				display: block;
				max-width: 890px;
				margin: 0 auto;
				text-align: center;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 20px;
				}
			}
		}
		.box{
			width: 33.3%;
			height: 330px;
			background-repeat: no-repeat;
			background-size: cover;
			&:after{
				width: 35px;
				height: 35px;
			}
			&.blank.red{
				display: flex;
				align-items: flex-end;
				p.item_title{
					text-transform: uppercase;
				}
			}
		}
	}
}
.block_poster{
	.poster{
		margin-bottom: vw-mob(20px);
	}
	div.key{
		h2{
			@include vw-font(20, 24, 600);
			margin-bottom: vw-mob(20px);
		}
		p{
			@include vw-font(18, 22, 400);
			margin-bottom: vw-mob(10px);
		}
		ul{
			@include vw-font(18, 22, 400);
			li{
				margin-bottom: vw-mob(10px);
			}
		}
	}
	&.wide{
		.poster{
			height: vw-mob(370px);
		}
		.key{
			padding: vw-mob(10px) vw-mob(10px) vw-mob(80px) vw-mob(10px);
		}
	}
}
.sw_wrap{
	.switch_target{
		.text_wrap{
			display: none;
			position: relative;
			flex-shrink: 0;
			.more{
				position: absolute;
				bottom: 0;
				left: 0;
			}
			span {
				color: $RED;
			}
			&.active{
				display: block;
			}
		}
	}
	.key{
		p {
			@include vw-font(18, 22, 400);
		}
	}
}
.callme_key{
	max-width: unset;
	margin: unset;
	p {
		@include vw-font(18, 22, 400);
		margin-bottom: vw-mob(20px);
	}
}
.wrapper{
	max-width: unset;
	&.main{
		.promo_slider{
			@include promo_slider_adp;
		}
		.partners{
			padding: vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.content{
				height: 80vh;
				top: 0;
				border-radius: vw-mob(20px);
				padding: vw-mob(60px) vw-mob(20px) vw-mob(20px) vw-mob(20px);
				i{
					top: vw-mob(20px);
					right: vw-mob(10px);
					width: vw-mob(20px);
					height: vw-mob(20px);
				}
				.body{
					@include vw-font(18, 22, 400);
				}
			}
			.block-title{
				flex: 0 1 80%; // 3 * 8px
				@include vw-font(24, 32, 400);
			}
			.p_item{
				width: unset;
				height: vw-mob(70px);
				flex: 0 1 calc(100% / 3);
				background-image: var(--pt-bg);
				background-size: 70% auto;
				cursor: pointer;
				&::before,
				&::after{
					content: '';
					background-color: $T_GREYB5;
					width: 1px;
					height: vw-mob(55px);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: all .05s;
				}
				&:after{
					right: -2px;
				}
				&:before{
					left: -1px;
				}
				&:hover{
					transform: scale(.99);
					box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2) inset;
					&::after,
					&::before{
						opacity: 0;
					}
				}
			}
		}
		.crop_field{
			flex-direction: column;
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.part{
				&.left{
					width: 100%;
					margin-bottom: vw-mob(10px);
					h2.headline{
						@include vw-font(24, 32, 400);
						margin-bottom: vw-mob(20px);
					}
					p.key{
						@include vw-font(16, 20, 400);
					}
				}
				&.right{
					min-width: unset;
					max-width: unset;
					.block_item{
						min-width: unset;
						max-width: calc(50% - 10px);
						height: vw-mob(200px);
						padding: vw-mob(30px) vw-mob(10px) vw-mob(10px) vw-mob(10px);
						margin: 0 0 vw-mob(10px) 0;
						@include vw-font(14, 16, 400);
						&:after{
							width: vw-mob(15px);
							height: vw-mob(15px);
							top: vw-mob(10px);
							right: vw-mob(10px);
						}
					}
				}
			}
		}
		.agrar_solutions{
			padding: vw-mob(50px) vw-mob(10px);
			h2.subtitle{
				@include vw-font(20, 26, 400);
				margin-bottom: vw-mob(20px);
			}
			p.key{
				@include font(16, 20, 400); // 100 - 26 - 20
				margin-bottom: unset;
			}
			.bg_image{
				width: 100%;
				height: vw-mob(200px);
				margin-left: unset;
				flex-shrink: 0;
			}
			.stage_wrap{
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
				margin-bottom: vw-mob(20px);
				.text_wrap{
					width: unset;
					padding: 0 0 vw-mob(30px) 0;
					margin-bottom: vw-mob(20px);
				}
			}
			.box{
				display: flex;
				flex-wrap: wrap;
				.agrar_item{
					border-radius: vw-mob(75px);
					@include vw-font(16, 40, 400);
					margin: 0 vw-mob(15px) vw-mob(10px) 0;
					padding: 0 vw-mob(20px);
					&:after{
						content: '';
						width: vw-mob(10px);
						height: vw-mob(10px);
						right: vw-mob(5px);
					}
				}
			}
		}
		.page_controls{
			display: flex;
			flex-direction: column;
			padding: vw-mob(50px) vw-mob(10px);
			ul.side{
				width: 100%;
				&.right{
					li:first-child{
						border-top: unset;
					}
				}
				li{
					a{
						@include vw-font(20, 70, 400);
						padding: 0 vw-mob(40px) 0 0; // 50px
						&:before{
							width: vw-mob(20px);
							height: vw-mob(20px);
						}
						&:after{
							width: vw-mob(30px);
							height: vw-mob(20px);
						}
						&:hover{
							width: 100%;
							padding: 0 vw-mob(40px) 0 vw-mob(30px);
						}
					}
				}
			}
		}
		.question_block{
			padding: vw-mob(80px) 0;
			h2.item-title{
				@include font(24, 32, 400);
				margin-bottom: vw-mob(10px);
				&:after{
					content: '';
					width: vw-mob(20px);
					height: vw-mob(20px);
					top: vw-mob(-25px);
					right: vw-mob(5px);
				}
			}
			p.key{
				@include vw-font(16, 20, 400);
				margin-bottom: vw-mob(20px);
				text-align: center;
			}
			a.send{
				padding: 0 vw-mob(20px);
				@include vw-font(16, 40, 400);
			}
		}
	}
	&.about_company{
		.mission{
			padding: vw-mob(80px) vw-mob(10px) vw-mob(40px) vw-mob(10px);
			margin-bottom: vw-mob(50px);
			flex-direction: column;
			.part{
				&.left{
					width: 100%;
					margin-bottom: vw-mob(30px);
					.round{
						width: vw-mob(140px);
						height: vw-mob(140px);
						&:first-child{
							margin: 0 vw-mob(70px) vw-mob(-7px) vw-mob(70px);
						}
						&:not(:first-child){
							margin: 0 vw-mob(7px);
						}
						p.info{
							@include vw-font(20, 26, 400);
						}
					}
				}
				&.right{
					padding: 0 0 0 vw-mob(60px);
					min-height: vw-mob(100px);
					.key{
						p{
							@include vw-font(18, 22, 400)
						}
					}
				}
			}
		}
		.values{
			@include values_block_adp;
		}
		.agrocoach{
			@include agrocoach_block_adp;
		}
		.social_resp{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				.box{
					padding: vw-mob(20px) vw-mob(20px) vw-mob(50px) vw-mob(20px);
					height: unset;
					min-height: vw-mob(180px);
					&:not(.full),
					&:not(.half2){
						flex-basis: calc(100% / 2);
					}
					&.half2{
						flex-basis: 100;
					}
				}
			}
		}
		.library{
			padding: vw-mob(40px) vw-mob(10px) vw-mob(20px) vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}
			.main_video_gal{
				margin-bottom: vw-mob(50px);
				padding: 0 0 vw-mob(10px) 0;
				.gal_wrapper{
					height: vw-mob(350px);
					margin-left: unset; // slider shift fix
				}
				.alt_item{
					width: 100%; // keep wide to avoid shifting
					height: vw-mob(300px);
					transition: all .25s;
					&.swiper-slide-active{
						width: 100%;
						height: vw-mob(300px);
					}
				}
				.gal_pagination{
					width: 100%;
					height: 2px;
					left: unset;
				}
				.gal_controls{
					bottom: vw-mob(20px);
					right: 0;
					width: vw-mob(80px);
					height: vw-mob(20px);
					a.btn{
						width: vw-mob(30px);
						height: vw-mob(20px);
					}
				}
			}
			.news_tail{
				position: absolute;
				width: vw-mob(220px);
				bottom: vw-mob(87px);
				left: vw-mob(10px);
				justify-content: flex-start;
				a.arrow{
					position: relative;
					padding: 0 vw-mob(25px) 0 0;
					@include vw-font(18, 22, 400);
					&:after{
						width: vw-mob(25px);
						height: vw-mob(22px);
					}
				}
			}
		}
		.learn{
			margin-bottom: vw-mob(50px);
			padding: 0 vw-mob(10px);
			.headline{
				width: 100%;
				margin-bottom: vw-mob(40px);
				h2{
					@include vw-font(24, 32, 400);
					margin-bottom: vw-mob(20px);
				}
				p{
					@include vw-font(18, 22, 400);
					margin-bottom: vw-mob(10px);
				}
			}
			.box_wrap{
				padding: 0;
				display: flex;
				justify-content: space-between;
				.box{
					width: calc(50% - 2.66667vw); // 10px
					min-height: vw-mob(380px);
					height: unset;
					padding: 0 vw-mob(20px);
					display: flex;
					align-items: center;
					p.item_title{
						@include vw-font(20, 24, 400);
						text-align: center;
						max-width: unset;
					}
					&::after{
						width: vw-mob(20px);
						height: vw-mob(20px);
						bottom: vw-mob(20px);
						left: vw-mob(20px);
					}
					&.bg{
						background-position: center;
					}
				}
			}
		}
	}
	&.farming{
		.farming_poster{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.box_wrap{
				height: auto;
				margin-bottom: vw-mob(20px);
				.box{
					flex-basis: 100%;
					border: 1px solid $WHITE;
					padding-bottom: vw-mob(60px);
				}
			}
			p.farming_alt{
				@include vw-font(18, 22, 400);
				padding: 0 0 0 vw-mob(60px);
			}
		}
		.pack_services{
			padding: 0 vw-mob(10px) vw-mob(40px) vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(30px);
			}
			.service_box{
				flex-direction: column;
				.box_item{
					width: 100%;
					height: vw-mob(300px);
					margin-bottom: vw-mob(20px);
					&:last-child{
						margin-bottom: unset;
					}
					.image{
						width: 100%;
						height: vw-mob(255px);
					}
					.info{
						background-color: $BG_BLACK;
						@include vw-font(16, 16, 400);
						padding: vw-mob(15px) vw-mob(40px) vw-mob(10px) vw-mob(10px);
						&:before{
							width: vw-mob(15px);
							height: vw-mob(15px);
							right: vw-mob(10px);
						}

					}
				}
			}
		}
		.irrigation{
			padding: vw-mob(50px) vw-mob(10px);
			flex-direction: column;
			margin-bottom: vw-mob(50px);
			.side{
				&.left{
					width: 100%;
					padding: 0 0 vw-mob(40px) 0;
					margin-bottom: vw-mob(20px);
					a.arrow{
						bottom: vw-mob(10px);
					}
					h2.item_title{
						@include vw-font(24, 32, 400);
						margin-bottom: vw-mob(20px);
					}
					p.key{
						@include vw-font(18, 22, 400);
					}
				}
				&.right{
					width: 100%;
				}
			}
			.poster{
				height: vw-mob(220px);
			}
		}
		.po{
			@include po_block_adp;
		}
	}
	&.machinery{
		.agrar_solutions{
			padding: vw-mob(50px) vw-mob(10px);
			margin-bottom: unset;
			h2.subtitle{
				@include vw-font(20, 26, 400);
				margin-bottom: vw-mob(20px);
			}
			p.key{
				@include font(16, 20, 400); // 100 - 26 - 20
				margin-bottom: unset;
			}
			.bg_image{
				width: 100%;
				height: vw-mob(200px);
				margin-left: unset;
				flex-shrink: 0;
			}
			.stage_wrap{
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
				margin-bottom: vw-mob(20px);
				.text_wrap{
					width: unset;
					padding: 0 0 30px 0;
					margin-bottom: vw-mob(20px);
				}
			}
			.box{
				display: flex;
				flex-wrap: wrap;
				.agrar_item{
					border-radius: vw-mob(75px);
					@include vw-font(16, 40, 400);
					margin: 0 vw-mob(15px) vw-mob(10px) 0;
					padding: 0 vw-mob(20px);
					&:after{
						content: '';
						width: vw-mob(10px);
						height: vw-mob(10px);
						right: vw-mob(5px);
					}
				}
			}
		}
	}
	&.agroculture{
		.agrar_solutions{
			padding: vw-mob(40px) vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.alt{
				width: 100%;
				h2{
					@include vw-font(20, 26, 400);
					margin-bottom: vw-mob(20px);
				}
				p{
					@include font(16, 20, 400); // 100 - 26 - 20
					margin-bottom: unset;
				}
			}
			.text_wrap{
				padding: 0 0 va-mob(40px) 0;
			}
			.bg_image{
				width: 100%;
				height: vw-mob(200px);
				margin-left: unset;
				flex-shrink: 0;
			}
			.stage_wrap{
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
				margin-bottom: vw-mob(20px);
				min-height: vw-mob(400px);
				.text_wrap{
					width: unset;
					padding: 0 0 30px 0;
					margin-bottom: vw-mob(20px);
				}
			}
			.box{
				display: flex;
				flex-wrap: wrap;
				margin-bottom: vw-mob(20px);
				.agrar_item{
					border-radius: vw-mob(75px);
					@include vw-font(16, 40, 400);
					margin: 0 vw-mob(15px) vw-mob(10px) 0;
					padding: 0 vw-mob(20px);
					&:after{
						content: '';
						width: vw-mob(10px);
						height: vw-mob(10px);
						right: vw-mob(5px);
					}
				}
			}
		}
		.fertilizers{
			padding: vw-mob(40px) 0;
			margin-bottom: unset;
			.headline{
				padding: 0 vw-mob(10px);
				h2{
					@include vw-font(24, 32, 400);
					margin-bottom: vw-mob(20px);
				}
				p{
					@include vw-font(18, 22, 400);
					max-width: unset;
					margin-bottom: vw-mob(40px);
				}
			}
			.farming_poster{
				.box_wrap{
					width: 100%;
					height: vw-mob(590px);
					margin-bottom: unset;
					.box{
						padding-top: unset;
						flex-basis: calc(100% / 4);
						width: calc(100% / 4);
						border-right: 1px solid $WHITE;
						border-left: 1px solid $WHITE;
						&:nth-child(odd){
							border-right: 1px solid $WHITE;
							border-left: 1px solid $WHITE;
						}
						display: flex;
						align-items: center;
						justify-content: center;
						p.item_title{
							@include vw-font(32, 42, 400);
							transform: rotate(-90deg);
							width: vw-mob(590px);
							flex-shrink: 0;
							text-align: center;
						}
						p.key{
							display: none;
						}
					}
				}
				.btn_wrap{
					margin-top: vw-mob(40px);
					a{
						@include vw-font(24, 50, 400);
						padding: 0 vw-mob(20px);
					}
				}
			}
		}
		.bio{
			@include bio_block_adp;
		}
		&.item_culture{
			.block_poster{
				margin-bottom: vw-mob(50px);
			}
			.fertilizers{
				p{
					max-width: 1100px;
					margin-bottom: 40px;
				}
			}
		}
		&.fertilizers-page{
			.fertilizers{
				padding: vw-mob(20px) vw-mob(10px);
			}
			article.group_wrapper{
				padding: 0 vw-mob(10px);
				section.group_wrap{
					.section_name{
						flex-direction: column;
						justify-content: flex-start;
						margin-bottom: vw-mob(20px);
						.poster{
							width: vw-mob(270px);
							height: vw-mob(100px);
						}
						.key{
							@include vw-font(18, 22, 400);
							width: 100%;
							p{
								margin-bottom: vw-mob(10px);
								&:last-child{
									margin-bottom: unset;
								}
							}
						}
					}
					.tags_holder{
						overflow-x: auto;
						overflow-y: hidden;
					}
					.tags_head{
						padding: vw-mob(20px) 0;
						justify-content: flex-start;
						flex-wrap: wrap;
						width: max-content;
						.tag{
							padding: 0 vw-mob(20px) 0 vw-mob(15px);
							margin: 0 vw-mob(5px);
							height: vw-mob(40px);
							border-radius: vw-mob(50px);
							@include vw-font(20, 37, 400);
							&:after{
								width: vw-mob(10px);
								height: vw-mob(10px);
								right: vw-mob(7px);
							}
						}
					}
					.wrapper_controls{
						.btn{
							width: vw-mob(50px);
							height: vw-mob(50px);
							background-size: 70% auto;
							top: vw-mob(220px);
							&.next{
								left: calc(100% - vw-mob(50px));
							}
						}
					}
					.items_wrap{
						margin-bottom: vw-mob(30px);
						.item_fertilizer{
							width: 100%;
							height: auto;
							display: flex;
							flex-direction: column;
							justify-content: flex-start;
							background-color: $WHITE;
							.side{
								&.image{
									width: 100%;
									height: vw-mob(320px);
								}
								&.info{
									width: 100%;
									padding: vw-mob(20px) vw-mob(10px) vw-mob(20px) vw-mob(10px);
									h3{
										@include vw-font(28, 32, 400);
										margin-bottom: vw-mob(20px);
									}
									.description{
										@include vw-font(18, 22, 400);
										p{
											margin-bottom: vw-mob(10px);
											&:last-child{
												margin-bottom: unset;
											}
										}
										ul {
											margin-bottom: vw-mob(10px);
											padding: 0 0 0 vw-mob(20px);
											li{
												margin-bottom: vw-mob(10px);
												&:last-child{
													margin-bottom: unset;
												}
												list-style: disc;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.question_block{
				padding: vw-mob(80px) 0;
				h2.item-title{
					@include font(24, 32, 400);
					margin-bottom: vw-mob(10px);
					&:after{
						content: '';
						width: vw-mob(20px);
						height: vw-mob(20px);
						top: vw-mob(-25px);
						right: vw-mob(5px);
					}
				}
				p.key{
					@include vw-font(16, 20, 400);
					margin-bottom: vw-mob(20px);
					text-align: center;
				}
				a.send{
					padding: 0 vw-mob(20px);
					@include vw-font(16, 40, 400);
				}
			}
		}
	}
	&.agroinfo{
		.agrospace{
			padding: vw-mob(40px) vw-mob(10px) vw-mob(30px) vw-mob(10px);
			flex-direction: column-reverse;
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(25, 32, 400);
				width: 100%;
				top: vw-mob(40vw);
				left: vw-mob(10vw);
			}
			.alt_news{
				width: 100%;
				padding: vw-mob(20px) 0 0 0;
				margin-bottom: vw-mob(50px);
				.alt_item{
					p.subtitle{
						@include vw-font(18, 22, 400);
						margin-bottom: vw-mob(10px);
					}
					p.key{
						@include vw-font(16, 20, 400);
						margin-bottom: vw-mob(10px);
						&:last-child{
							margin-bottom: unset;
						}
					}
				}
			}
			.main_news_gal{
				position: relative;
				padding: vw-mob(50px) 0 0 0;
				width: 100%;
				margin-bottom: vw-mob(40px);
				.gal_wrapper{
					height: vw-mob(400px);
					margin-left: unset; // slider shift fix
				}
				.alt_item{
					width: 100%; // keep wide to avoid shifting
					height: vw-mob(350px);
					&.swiper-slide-active{
						width: 100%;
						height: vw-mob(350px);
					}
				}
				.news_pagination{
					width: 100%;
				}
				.gal_controls{
					position: absolute;
					bottom: vw-mob(40px);
					right: unset;
					left: vw-mob(10px);
					width: vw-mob(80px);
					height: vw-mob(20px);
					a.btn{
						width: vw-mob(30px);
						height: vw-mob(20px);
					}
				}
			}
			.news_tail{
				width: vw-mob(220px);
				bottom: vw-mob(30px);
				a.arrow{
					padding: 0 vw-mob(25px) 0 0;
					@include vw-font(18, 22, 400);
					&:after{
						width: vw-mob(25px);
						height: vw-mob(26px);
					}
				}
			}
		}
		.blog{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.headline{
				margin-bottom: vw-mob(20px);
				h2{
					@include vw-font(24, 32, 400);
				}
				a.btn{
					@include vw-font(18, 32, 400);
					padding: 0 vw-mob(15px);
				}
			}
			.box_wrap{
				flex-direction: column;
				margin-bottom: vw-mob(50px);
				.box{
					width: 100%;
					height: vw-mob(360px);
					margin-bottom: vw-mob(10px);
					&:last-child{
						margin-bottom: unset;
					}
					&:nth-last-child(-n+3) {
						margin-bottom: vw-mob(10px);
					}
					.alt_box{
						height: vw-mob(80px);
						display: flex;
						align-items: center;
						p{
							width: 100%;
							@include vw-font(17, 22, 400);
							padding: 0 vw-mob(50px) 0 vw-mob(15px);
							&::after{
								width: vw-mob(20px);
								height: vw-mob(20px);
								bottom: 0;
								right: vw-mob(15px);
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	&.map{
		padding: 0 vw-mob(10px);
		.map_wrapper{
			height: 60vh;
			#map{
				height: inherit;
			}
		}
		section.full_info{
			flex-direction: column;
			min-height: unset;
			padding: vw-mob(20px) 0 0 0;
			h2.table_head{
				display: none;
				flex-basis: 100%;
				flex-shrink: 0;
				@include font(20, 27, 400);
				text-transform: uppercase;
				text-align: right;
				padding: 30px 30px 30px 0;
				position: relative;
				&:after{
					content: '';
					position: absolute;
					right: 10px;
					top: 35px;
					width: 8px;
					height: 15px;
					background-image: url(../img/icon/ic-arrow3.svg);
					background-size: 350%;
					background-position: center;
					background-repeat: no-repeat;
					transform: rotate(270deg);
					transition: rotate .25s;
				}
				ul.table_filter{
					position: absolute;
					bottom: -100%;
					right: 0;
					padding: 10px 30px 10px 10px;
					border: 1px solid $LN_GREY;
					background-color: $WHITE;
					display: none;
					li{
						cursor: pointer;
					}
				}
			}
			.table_sw{
				// width: calc(100% - 2.666667vw);
				width: 100%;
				// .sw_item{
				// 	cursor: pointer;
				// 	border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
				// 	&:first-child{
				// 		border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
				// 	}
				// 	padding: 30px 0;
				// 	p.key{
				// 		@include font(24, 32, 400);
				// 		text-transform: uppercase;
				// 	}
				// 	&.active{
				// 		color: $RED;
				// 		p.key{
				// 			padding: 0 0 0 60px;
				// 			position: relative;
				// 			&:before{
				// 				content: '';
				// 				position: absolute;
				// 				width: 40px;
				// 				height: 40px;
				// 				border-radius: 50%;
				// 				background-color: $RED;
				// 				top: 50%;
				// 				transform: translateY(-50%);
				// 				left: 0;
				// 			}
				// 		}
				// 	}
				// }
				.alt_info{
					padding: vw-mob(20px) 0 0 0;
					margin: vw-mob(20px) 0 0 0;
					border-bottom: unset;
					h2{
						@include vw-font(18, 22, 400);
						padding: 0 0 0 vw-mob(20px);
						&:before{
							width: vw-mob(10px);
							height: vw-mob(10px);
						}
					}
				}
				#map_head{
					width: calc(100% - 1vw);
					@include vw-font(24, 40, 400);
				}
			}
			.table_body{
				max-width: 100%;
				margin-bottom: vw-mob(50px);
				margin-top: vw-mob(20px);
				.part_data{
					border-top: 2px solid $FULL_BLACK;
					.part_head{
						padding: vw-mob(20px) 0 vw-mob(10px) 0;
						@include vw-font(28, 32, 400);
					}
					.part_body{
						padding: 0 0 vw-mob(40px) 0;
						a{
							@include vw-font(16, 22, 400);
							margin-bottom: vw-mob(15px);
							padding: 0 0 0 vw-mob(20px);
						}
						p{
							@include font(16, 22, 400);
							margin-bottom: vw-mob(15px);
							&.ico.point{
								position: relative;
								padding: 0 0 0 vw-mob(20px);
								&:before{
									width: vw-mob(10px);
									height: vw-mob(15px);
									position: absolute;
									left: vw-mob(10px);
								}
							}
							&:last-child{
								margin-bottom: unset;
							}
						}
					}
				}
			}
		}
	}
	&.catalog{
		padding: 0 vw-mob(10px);
		overflow-x: hidden;
		.catalog_head{
			margin-bottom: vw-mob(20px);
			.block_iamge{
				flex-direction: column-reverse;
				margin-bottom: vw-mob(20px);
				.poster{
					width: 100%;
					height: vw-mob(320px);
					margin-bottom: vw-mob(10px);
				}
				.alt{
					width: 100%;
					p{
						@include vw-font(16, 20, 400);
						margin-bottom: vw-mob(10px);
						&:last-child{
							margin-bottom: unset;
						}
					}
					a.margin{
						margin: vw-mob(20px) 0 0 auto;
						padding: 0 vw-mob(15px);
						@include vw-font(16, 40, 400);
					}
				}
			}
			.block_text{
				padding: vw-mob(310px) vw-mob(10px) vw-mob(20px) vw-mob(10px);
				&::after{
					content: '';
					width: calc(100% - 5.33334vw);
					height: vw-mob(280px);
					position: absolute;
					top: vw-mob(10px);
					left: vw-mob(10px);
				}
				p{
					@include vw-font(16, 20, 400);
					margin-bottom: vw-mob(10px);
					&:last-child{
						margin-bottom: unset;
					}
					&.margin{
						margin-top: unset;
					}
				}
				ul{
					padding: 0 0 0 vw-mob(20px);
					li {
						@include vw-font(16, 20, 400);
					}
				}
			}
		}
		.filter{
			width: 80vw;
			margin-right: vw-mob(10px);
			position: relative;
			.expander_btn{
				position: absolute;
				width: vw-mob(40px);
				height: vw-mob(40px);
				background-color: $WHITE;
				top: vw-mob(10px);
				right: vw-mob(-30px);
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $T_GREY82;
				z-index: 2;
				border-radius: 50%;
				i{
					position: relative;
					width: vw-mob(15px);
					height: vw-mob(15px);
					&::before{
						content: '';
						position: absolute;
						width: vw-mob(15px);
						height: vw-mob(15px);
						background-image: url(../img/icon/ic-arrow3.svg);
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
						transition: rotate .250s;
						transform: rotate(180deg);
					}
				}
			}
			.box{
				padding: 0 0 vw-mob(20px) 0;
				p.b_head{
					@include vw-font(16, 20, 400);
					margin-bottom: vw-mob(10px);
				}
				.filter_body{
					padding: vw-mob(10px) vw-mob(20px);
					&.collapse{
						height: vw-mob(215px);
					}
					&.sml{
						height: vw-mob(165px);
					}
					li.chk_item{
						margin-bottom: vw-mob(10px);
						align-items: flex-end;
						label{
							width: vw-mob(22px);
							height: vw-mob(22px);
							margin-right: vw-mob(10px);
							&:before{
								width: vw-mob(22px);
								height: vw-mob(22px);
							}
						}
						input[type="checkbox"]{
							&:checked + label::after{
								width: vw-mob(22px);
								height: vw-mob(22px);
							}
						}
						p.key{
							@include vw-font(18, 20, 400);
							span.count{
								margin-left: vw-mob(10px);
							}
						}
					}
				}
				.all_trigger{
					color: var(--promo-color);
					cursor: pointer;
					position: absolute;
					background-color: $WHITE;
					@include font(16, 24, 400);
					bottom: 0;
					width: 100%;
					height: 50px;
					align-items: flex-start;
					padding: 10px 20px;
				}
				.filter_head{
					padding: 10px 20px;
					background-color: $BG_WHITE;
					a.toggle{
						width: 28px;
						height: 28px;
						background-size: 15px auto;
						position: absolute;
						top: 10px;
						right: 20px;
						transform: rotate(180deg);
						transition: transform .25s;
						&.open{
							transform: rotate(0deg);
						}
					}
				}
				&.used_data{
					padding-top: 40px;
					position: sticky;
					top: 0;
					z-index: 1;
					.filter_head{
						padding: 0 20px;
						background-color: unset;
						margin-bottom: 20px;
					}
				}
				.apply_filter,
				.clear_filter{
					position: absolute;
					top: 42px;
					right: 20px;
					@include font(16, 21, 400);
					color: $T_GREY82;
					&.apply_filter{
						top: unset;
						bottom: 10px;
						opacity: 0;
						transition: opacity .25s;
						&.shown{
							opacity: 1;
						}
					}
				}
				.filter_item{
					flex-basis: 1;
					@include font(12, 25, 400);
					padding: 0 30px 0 10px;
					background-color: $BG_GREY;
					border-radius: 18px;
					position: relative;
					margin: 0 10px 10px 0;
					i.del{
						position: absolute;
						top: 9px;
						right: 14px;
						width: 8px;
						height: 8px;
					}
				}

			}
		}
		.catalog_body{
			display: flex;
			justify-content: space-between;
			position: relative;
			left: -76vw;
			width: 170vw;
			&.menu_open{
				left: 0;
				.filter{
					.expander_btn{
						i::before{
							transform: rotate(0);
						}
					}
				}
			}
		}
		.items_wrap{
			display: flex;
			flex-wrap: wrap;
			width: 83vw;
			.item{
				margin-bottom: vw-mob(20px);
			}
		}
		// .pagenator{
		// 	padding: 0 0 0 345px;
		// 	.btns{
		// 		display: flex;
		// 		justify-content: center;
		// 		margin-top: 30px;
		// 		margin-bottom: 100px;
		// 	}
		// 	.btn{
		// 		background-image: url(../img/icon/ic-arrow3.svg);
		// 		background-size: auto 20px;
		// 		background-position: center;
		// 		width: 40px;
		// 		height: 40px;
		// 		cursor: pointer;
		// 		transition: box-shadow .25s;
		// 		&.next{
		// 			transform: rotate(180deg);
		// 		}
		// 		&.disabled{
		// 			opacity: .25;
		// 			pointer-events: none;
		// 		}
		// 		&:hover{
		// 			box-shadow: 0 0 10px 0 rgba(0, 0, 0, .28);
		// 		}
		// 	}
		// 	ul.pages{
		// 		display: flex;
		// 		li{
		// 			@include font(16, 40, 400);
		// 			width: 16px;
		// 			margin: 0 10px;
		// 			text-align: center;
		// 			&.active{
		// 				border-bottom: 4px solid var(--promo-color);
		// 			}
		// 		}
		// 	}
		// }
		&[data-cat="machinery"]{
			.items_wrap{
				.item{
					height: vw-mob(360px);
					&:hover::after{
						content: '';
						position: absolute;
						width: vw-mob(10px);
						height: vw-mob(10px);
						bottom: vw-mob(10px);;
						right: vw-mob(30px);;
					}
					.info{
						margin-bottom: unset;
						a.key{
							@include vw-font(18, 22, 400);
						}
					}
				}
			}
		}
	}
	&.finprogs{
		.block_poster{
			// margin-bottom: 100px;
			.slide_item{
				.bg{
					height: 100%;
					.part{
						&.right{
							height: vw-mob(455px);
						}
					}
				}
				.data{
					.part{
						&.left{
							padding: vw-mob(180px) vw-mob(30px) vw-mob(90px) vw-mob(30px);
							width: 100%;
							&:after{
								content: '';
								position: absolute;
								width: 84vw;
								height: 79vw;
								top: vw-mob(310px);
								left: vw-mob(20px);
								border-radius: vw-mob(20px);
								background: linear-gradient(180deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .6) 100%);
							}
							h2.item_title{
								@include vw-font(24, 28, 400);
								margin-bottom: vw-mob(15px);
							}
							p.info{
								@include vw-font(16, 21, 400);
							}
							a.slider{
								padding: 0 vw-mob(20px);
								height: vw-mob(40px);
								@include vw-font(16, 40, 400);
								bottom: vw-mob(130px);
								left: vw-mob(30px);
							}
							.socials{
								position: absolute;
								top: unset;
								transform: none;
								bottom: vw-mob(80px);
								left: vw-mob(30px);
								height: unset;
								width: vw-mob(140px);
								flex-direction: row;
							}
						}
					}
				}
				&.t2{
					.data{
						.part{
							&.left{
								padding: vw-mob(70px) vw-mob(30px) vw-mob(90px) vw-mob(30px);
								a.slider{
									left: vw-mob(30px);
								}
								.socials{
									left: vw-mob(30px);
									right: unset;
								}
							}
						}
					}
				}
			}
		}
		.agrospace{
			padding: vw-mob(40px) vw-mob(10px) vw-mob(30px) vw-mob(10px);
			flex-direction: column-reverse;
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(25, 32, 400);
				width: 100%;
				top: vw-mob(40vw);
				left: vw-mob(10vw);
			}
			.alt_news{
				width: 100%;
				padding: vw-mob(20px) 0 0 0;
				margin-bottom: vw-mob(50px);
				.alt_item{
					p.subtitle{
						@include vw-font(18, 22, 400);
						margin-bottom: vw-mob(10px);
					}
					p.key{
						@include vw-font(16, 20, 400);
						margin-bottom: vw-mob(10px);
						&:last-child{
							margin-bottom: unset;
						}
					}
				}
			}
			.main_news_gal{
				position: relative;
				padding: vw-mob(50px) 0 0 0;
				width: 100%;
				margin-bottom: vw-mob(40px);
				.gal_wrapper{
					height: vw-mob(400px);
					margin-left: unset; // slider shift fix
				}
				.alt_item{
					width: 100%; // keep wide to avoid shifting
					height: vw-mob(350px);
					&.swiper-slide-active{
						width: 100%;
						height: vw-mob(350px);
					}
				}
				.news_pagination{
					width: 100%;
				}
				.gal_controls{
					position: absolute;
					bottom: vw-mob(40px);
					right: unset;
					left: vw-mob(10px);
					width: vw-mob(80px);
					height: vw-mob(20px);
					a.btn{
						width: vw-mob(30px);
						height: vw-mob(20px);
					}
				}
			}
			.news_tail{
				width: vw-mob(220px);
				bottom: vw-mob(30px);
				a.arrow{
					padding: 0 vw-mob(25px) 0 0;
					@include vw-font(18, 22, 400);
					&:after{
						width: vw-mob(25px);
						height: vw-mob(26px);
					}
				}
			}
		}
		.page_controls{
			padding: vw-mob(20px) vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item-title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(30px);
			}
			.key{
				p {
					@include vw-font(18, 22, 400);
				}
			}
			ul.side{
				margin-bottom: vw-mob(20px);
				li{
					padding: vw-mob(20px) 0;
					a{
						@include vw-font(18, 22, 400);
						padding: 0 vw-mob(40px) 0 0; // 50px
						&:before{
							content: '';
							width: vw-mob(20px);
							height: vw-mob(20px);
						}
						&:after{
							width: vw-mob(20px);
							height: vw-mob(20px);
						}
						&:hover{
							padding: 0 vw-mob(40px) 0 vw-mob(25px);
						}
					}
				}
			}
		}
		.fin_progs{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.partners_box{
				margin-bottom: vw-mob(50px);
				.box{
					width: 100%;
					height: vw-mob(200px);
					margin-bottom: vw-mob(10px);
				}
			}
			a.red{
				padding: 0 vw-mob(15px);
				@include vw-font(16, 40, 400);
			}
		}
	}
	&.innovation{
		.farming_poster{
			padding: 0 40px;
			margin-bottom: 100px;
			.box_wrap{
				width: 100%;
				height: 700px;
				background-image: var(--p-bg);
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 40px;
				.box{
					flex-basis: calc(100% / 3);
					border-right: 1px solid $WHITE;
					border-left: 1px solid $WHITE;
				}
			}
			.farming_alt{
				p {
					@include font(28, 37, 400);
					padding: 0 0 0 480px;
				}
			}
		}
		.fs_head{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.headline{
				h2{
					@include vw-font(24, 32, 400);
					margin-bottom: vw-mob(30px);
				}
				ul{
					padding: unset;
					@include vw-font(16, 20, 400);
					margin-bottom: vw-mob(20px);
				}
			}
		}
		.pack_services{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(30px);
			}
			.service_box{
				justify-content: space-between;
				margin-bottom: vw-mob(40px);
				.box_item{
					width: 100%;
					min-height: vw-mob(500px);
					height: auto;
					margin-bottom: vw-mob(10px);
					&:last-child{
						margin-bottom: unset;
					}
					.image{
						width: 100%;
						height: vw-mob(280px);
					}
					p.info{
						@include vw-font(17, 22, 400);
						padding: vw-mob(20px) vw-mob(10px);
						display: flex;
						align-items: center;
						&:before{
							width: vw-mob(18px);
							height: vw-mob(18px);
							top: 50%;
							transform: translateY(-50%);
							right: vw-ob(10px);
						}
						&:after{
							width: vw-mob(10px);
							height: vw-mob(10px);
							top: vw-mob(8px);
							right: vw-mob(16px);
						}
					}
					.legend {
						@include vw-font(16, 20, 400);
						padding: vw-mob(20px);
						min-height: vw-mob(130px);
					}
				}
			}
		}
		.po{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}	
			.table_wrap{
				display: flex;
				flex-direction: column;
				min-height: unset;
				position: relative;
				margin-bottom: vw-mob(40px);
			}
			.table_sw{
				width: 100%;
				position: sticky;
				top: 0;
				background-color: $BG_WHITE;
				z-index: 1;
				.sw_item{
					display: flex;
					height: vw-mob(50px);
					border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					&:first-child{
						border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
						border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					p.key{
						@include vw-font(18, 22, 400);
					}
					&.active{
						color: var(--promo-color);
						height: vw-mob(60px);
						p.key{
							padding: 0 0 0 vw-mob(30px);
							&:before{
								width: vw-mob(20px);
								height: vw-mob(20px);
							}
						}
					}
				}
			}
			.table_body{
				width: 100%;
				.body_item{
					border-top: 2px solid $FULL_BLACK;
					border-bottom: 2px solid $FULL_BLACK;
					&:first-child{
						border-top: unset;
					}
					tr{
						td,
						th{
							.poster{
								width: vw-mob(400px);
								height: vw-mob(240px);
							}
						}
						th{
							padding: vw-mob(10px) 0;
						}
						td{
							padding: vw-mob(10px) vw-mob(10px) vw-mob(10px) vw-mob(20px);
							&.active{
								padding: vw-mob(20px) vw-mob(20px) vw-mob(20px) vw-mob(50px);
							}
							p.subtitle{
								@include vw-font(18, 32, 400);
								margin-bottom: vw-mob(10px);
							}
							.key{
								@include vw-font(14, 17, 400);
								ul{
									li {
										list-style: disc;
										margin-bottom: vw-mob(5px);
										&:last-child{
											margin-bottom: unset;
										}
									}
								}
							}
							a.more{
								@include vw-font(14, 17, 400);
								bottom: vw-mob(10px);
								left: vw-mob(40px);
							}
						}
					}
				}
			}
		}
		.social_resp{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				.box{
					padding: vw-mob(20px) vw-mob(10px);
					height: vw-mob(330px);
					&:not(.full),
					&:not(.half2){
						flex-basis: calc(100% / 2);
						&:nth-child(4){
							border-top: unset;
							border-right: unset;
						}
						&:nth-child(6){
							border-left: unset;
							border-bottom: unset;
						}
					}
					&.half2{
						flex-basis: 100%;
						margin-left: unset;
						p.item_title{
							@include vw-font(18, 22, 400);
							margin-bottom: vw-mob(10px);
						}
						.text_wrap{
							&::after{
								width: vw-mob(30px);
								height: vw-mob(20px);
								bottom: vw-mob(10px);
								right: vw-mob(10px);
							}
						}
					}
				}
			}
		}
	}
	&.blog_page{
		.library{
			padding: vw-mob(20px) vw-mob(10px) vw-mob(10px) vw-mob(10px);
			margin-bottom: vw-mob(30px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}
			.main_video_gal{
				margin-bottom: vw-mob(30px);
				padding: 0 0 vw-mob(40px) 0;
				.gal_wrapper{
					height: vw-mob(300px);
					margin-left: unset; // slider shift fix
				}
				.alt_item{
					width: 100%; // keep wide to avoid shifting
					height: (300px);
					transition: all .25s;
					&.swiper-slide-active{
						width: 100%;
						height: vw-mob(300px);
					}
				}
				.gal_pagination{
					width: 100%;
					left: 0;
				}
				.gal_controls{
					bottom: vw-mob(10px);
					right: unset;
					left: 0;
					width: vw-mob(80px);
					height: vw-mob(20px);
					a.btn{
						width: vw-mob(30px);
						height: vw-mob(20px);
					}
				}
			}
			.news_tail{
				display: none;
				// position: absolute;
				// width: 220px;
				// bottom: 0;
				// left: calc(50% - 110px);
				// display: flex;
				// justify-content: center;
				// a.arrow{
				// 	position: relative;
				// 	padding: 0 25px 0 0;
				// 	@include font(20, 26, 400);
				// 	color: $RED;
				// 	&:after{
				// 		content: '';
				// 		position: absolute;
				// 		width: 25px;
				// 		height: 26px;
				// 		background-image: url(../img/icon/ic-arrow_red.svg);
				// 		background-repeat: no-repeat;
				// 		background-size: 80%;
				// 		background-position: center;
				// 		top: 0;
				// 		right: 0;
				// 	}
				// }
			}
		}
		.blog{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.headline{
				margin-bottom: vw-mob(20px);
				h2{
					@include vw-font(24, 32, 400);
				}
			}
			.box_wrap{
				flex-direction: column;
				margin-bottom: vw-mob(50px);
				.box{
					width: 100%;
					height: vw-mob(360px);
					margin-bottom: vw-mob(10px);
					&:last-child{
						margin-bottom: unset;
					}
					&:nth-last-child(-n+3) {
						margin-bottom: vw-mob(10px);
					}
					.alt_box{
						height: vw-mob(80px);
						display: flex;
						align-items: center;
						p{
							width: 100%;
							@include vw-font(17, 22, 400);
							padding: 0 vw-mob(50px) 0 vw-mob(15px);
							&::after{
								width: vw-mob(20px);
								height: vw-mob(20px);
								bottom: 0;
								right: vw-mob(15px);
								opacity: 1;
							}
						}
					}
				}
			}
			.news_tail{
				display: none;
			}
		}
	}
	&.pack_service{
		.farming_poster{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.box_wrap{
				height: auto;
				margin-bottom: vw-mob(20px);
				.box{
					flex-basis: 100%;
					border: 1px solid $WHITE;
					padding-bottom: vw-mob(60px);
				}
			}
			p.farming_alt{
				@include vw-font(18, 22, 400);
				padding: 0 0 0 vw-mob(60px);
			}
		}
		.pack_services{
			padding: 0 vw-mob(10px) vw-mob(50px) vw-mob(10px);
			margin-bottom: unset;
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}
			// .service_tail{
			// 	position: absolute;
			// 	bottom: 0;
			// 	display: flex;
			// 	justify-content: center;
			// 	width: 100%;
			// }
			.service_box{
				flex-direction: column;
				.box_item{
					width: 100%;
					margin-bottom: vw-mob(10px);
					&:nth-last-child(-n+3){
						margin-bottom: vw-mob(10px);
					}
					.image{
						width: 100%;
						height: vw-mob(260px);
					}
					.info{
						height: vw-mob(90px);
						p{
							@include vw-font(16, 20, 400);
							span{
								@include vw-font(12, 14, 400);
								&.hider{
									position: relative;
									padding: 0 vw-mob(15px) 0 0;
									cursor: pointer;
									&:after{
										width: vw-mob(12px);
										height: vw-mob(12px);
										top: -1px;
										@include vw-font(10, 16, 400);
									}
									&:before{
										top: vw-mob(-175px);
										left: vw-mob(-77px);
										height: vw-mob(120px);
										width: 94.6666vw;
										@include vw-font(16, 20, 400);
										padding: vw-mob(10px) vw-mob(20px);
									}
									&:hover{
										&:before{
											opacity: 1;
										}
									}
								}

							}
							&.smaller{
								@include vw-font(14, 18, 400);
							}
						}
						padding: vw-mob(20px) vw-mob(50px) vw-mob(20px) vw-mob(20px);
						&:before{
							width: vw-mob(20px);
							height: vw-mob(20px);
							right: vw-mob(15px);
						}
					}
				}
			}
		}
	}
	&.splicing{
		// .fertilizers{
		// 	padding: vw-mob(10px) vw-mob(10px) 0 vw-mob(10px);
		// 	margin-bottom: vw-mob(40px);
		// 	.headline{
		// 		h2{
		// 			@include vw-font(24, 32, 400);
		// 			margin-bottom: vw-mob(20px);
		// 		}
		// 	}
		// 	.farming_poster{
		// 		.box_wrap{
		// 			width: 100%;
		// 			height: unset;
		// 			margin-bottom: vw-mob(40px);
		// 			flex-direction: column;
		// 			.box{
		// 				flex-basis: 100%;
		// 				padding-top: vw-mob(40px);
		// 				p.item-title {
		// 					@include vw-font(20, 28, 400)
		// 				}
		// 				&:nth-child(odd) {
		// 					border: 1px solid $WHITE;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		.promo_slider{
			@include promo_slider_adp{
				margin-bottom: 0;
				.slide_item{
					.bg .part.right .background{
						// background-size: contain;
						background-size: 60%;
						background-position: 48%;
					}
				}
			}
		}
		.agrocoach{
			@include agrocoach_block_adp;
		}
		.values{
			@include values_block_adp{
				.box_wrap{
					.box{
						min-height: vw-mob(230px);
					}
				}
			}
		}
		&.agrohim{
			.values .box_wrap .box{
				min-height: vw-mob(200px);
			}
			.chess_wrapper .box .bg_image{
				background-position: bottom right;
			}
		}
		.po{
			@include po_block_adp{
				.table_sw{
					.sw_item p.key{
						padding: 0;
					}
				}
				.table_body{
					min-height: unset;
				}
			}
		}
		.bio{
			@include bio_block_adp{
				margin-bottom: 0;
				.box{
					margin-bottom: 20px;
				}
			}
		}
		.chess_wrapper{
			@include chess_wrapper_adp;
		}
		.advantages{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			.subtitle_wrap{
				margin-bottom: vw-mob(40px);
				h2.item_title{
					@include vw-font(24, 32, 400);
				}
			}
			.box_wrap{
				padding: 0 0 0 0;
				.box{
					padding: vw-mob(15px) 0 vw-mob(15px) 0;
					p.key{
						@include vw-font(16, 18, 400);
					}
					&:before{
						left: vw-mob(10px);
						width: vw-mob(15px);
						height: vw-mob(15px);
					}
					&:hover{
						padding-left: vw-mob(35px);
					}
				}
				&.box4{
					.box{
						width: calc((100% / 4) - 40px);
						min-height: 350px;
					}
				}
			}
		}
		// .innovations{
		// 	padding: 0 vw-mob(10px);
		// 	h2.item_title{
		// 		@include vw-font(24, 32, 400);
		// 	}
		// 	.box_wrap{
		// 		flex-direction: column;
		// 		.box{
		// 			width: 100%;
		// 			height: vw-mob(250px);
		// 			&.blank{
		// 				display: none;
		// 			}
		// 			.key{
		// 				margin-bottom: vw-mob(40px);
		// 				p {
		// 					@include vw-font(16, 20, 400);
		// 				}
		// 			}
		// 			&:after{
		// 				width: vw-mob(20px);
		// 				height: vw-mob(20px);
		// 			}
		// 		}
		// 	}
		// }
		.agh_services{
			padding: 0 vw-mob(10px) vw-mob(40px) vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(30px);
			}
			.service_box{
				flex-direction: column;
				.box_item{
					width: 100%;
					height: vw-mob(300px);
					margin-bottom: vw-mob(20px);
					&:last-child{
						margin-bottom: unset;
					}
					.image{
						width: 100%;
						height: vw-mob(255px);
					}
					p.info{
						background-color: $BG_BLACK;
						@include vw-font(16, 16, 400);
						padding: vw-mob(15px) vw-mob(40px) vw-mob(10px) vw-mob(10px);
						&:before{
							width: vw-mob(15px);
							height: vw-mob(15px);
							right: vw-mob(10px);
						}
					}
				}
			}
		}
		.question_block{
			padding: vw-mob(80px) 0;
			h2.item-title{
				@include font(24, 32, 400);
				margin-bottom: vw-mob(10px);
				&:after{
					content: '';
					width: vw-mob(20px);
					height: vw-mob(20px);
					top: vw-mob(-25px);
					right: vw-mob(5px);
				}
			}
			p.key{
				@include vw-font(16, 20, 400);
				margin-bottom: vw-mob(20px);
				text-align: center;
			}
			a.send{
				padding: 0 vw-mob(20px);
				@include vw-font(16, 40, 400);
			}
		}
	}
	&.personal_page{
		.block_poster{
			margin-bottom: vw-mob(50px);
			.key{
				&:before{
					content: none;
				}
			}
		}
		.box{
			display: flex;
			margin-bottom: vw-mob(20px);
			flex-direction: column;
			.text_wrap{
				max-width: 100%;
				margin-bottom: vw-mob(20px);
				h2{
					@include vw-font(24, 32, 400);
					margin-bottom: vw-mob(20px);
				}
				p{
					@include vw-font(16, 22, 400);
				}
				ul{
					padding: 0 0 0 vw-mob(10px);
					margin-bottom: vw-mob(30px);
					li{
						@include vw-font(16, 22, 400);
					}
				}
				a.marked{
					@include vw-font(15, 22, 400);
				}
			}
			.bg_image{
				width: 100%;
				height: vw-mob(380px);
			}
			.type_title{
				flex-basis: 100%;
				margin-top: vw-mob(20px);
				h2{
					@include vw-font(24, 32, 400);
				}
			}
		}
		.chess_wrapper{
			padding: 0 vw-mob(10px);
			.box:nth-child(even){
				flex-direction: column;
			}
		}
		.question_block{
			padding: vw-mob(20px) vw-mob(10px) vw-mob(50px) vw-mob(10px);
			h2.item-title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(10px);
				text-align: center;
				&:after{
					width: vw-mob(15px);
					height: vw-mob(15px);
					top: vw-mob(-15px);
					right: vw-mob(-5px);
				}
			}
			p.key{
				@include vw-font(18, 22, 400);
				margin-bottom: vw-mob(20px);
			}
			a.send{
				padding: 0 vw-mob(20px);
				@include vw-font(16, 40, 400);
			}
		}
		.link_block{
			padding: vw-mob(20px) 0 0 0;
			margin-bottom: vw-mob(20px);
			h2.subtitle{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(40px);
			}
			.service_box{
				flex-direction: column;
				.box_item{
					width: 100%;
					height: vw-mob(403px);
					.image{
						height: vw-mob(330px);
					}
					p.info{
						@include vw-font(16, 20, 400);
						padding: vw-mob(20px) vw-mob(40px) vw-mob(20px) vw-mob(20px);
						&:before{
							width: vw-mob(15px);
							height: vw-mob(15px);
							right: vw-mob(10px);
						}
					}
				}
			}
		}
		.table_box{
			margin-bottom: vw-mob(30px);
			overflow-x: scroll;
			.table_init{
				width: 100%;
			}
		}
	}
	&.subscription,
	&.op-center,
	&.jd-link{
		.block_poster{
			.key{
				padding: vw-mob(20px) vw-mob(10px) vw-mob(50px) vw-mob(10px);
			}
		}
		&.jd-link{
			.block_poster{
				.key{
					padding: vw-mob(70px) vw-mob(10px) 0 vw-mob(10px);
					margin-bottom: vw-mob(40px);
					&:before{
						content: attr(data-content);
						@include vw-font(18, 22, 600);
						text-align: center;
						width: 94.66666vw;
						left: vw-mob(10px);
						top: vw-mob(10px);
						transform: translateY(0);
					}
				}
			}
		}
		.po{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(50px);
			h2.item_title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}	
			.table_wrap{
				display: flex;
				flex-direction: column;
				min-height: unset;
				position: relative;
				margin-bottom: vw-mob(40px);
			}
			.table_sw{
				width: 100%;
				position: sticky;
				top: 0;
				background-color: $BG_WHITE;
				z-index: 1;
				.sw_item{
					display: flex;
					height: vw-mob(50px);
					border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					&:first-child{
						border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
						border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					p.key{
						@include vw-font(18, 22, 400);
					}
					&.active{
						color: var(--promo-color);
						height: vw-mob(60px);
						p.key{
							padding: 0 0 0 vw-mob(30px);
							&:before{
								width: vw-mob(20px);
								height: vw-mob(20px);
							}
						}
					}
				}
			}
			.table_body{
				width: 100%;
				.body_item{
					border-top: 2px solid $FULL_BLACK;
					border-bottom: 2px solid $FULL_BLACK;
					&:first-child{
						border-top: unset;
					}
					tr{
						td,
						th{
							.poster{
								width: vw-mob(400px);
								height: vw-mob(240px);
							}
						}
						th{
							padding: vw-mob(10px) 0;
						}
						td{
							padding: vw-mob(10px) vw-mob(10px) vw-mob(10px) vw-mob(20px);
							&.active{
								padding: vw-mob(20px) vw-mob(20px) vw-mob(20px) vw-mob(50px);
							}
							p.subtitle{
								@include vw-font(18, 32, 400);
								margin-bottom: vw-mob(10px);
							}
							.key{
								@include vw-font(14, 17, 400);
								ul{
									li {
										list-style: disc;
										margin-bottom: vw-mob(5px);
										&:last-child{
											margin-bottom: unset;
										}
									}
								}
							}
							a.more{
								@include vw-font(14, 17, 400);
								bottom: vw-mob(10px);
								left: vw-mob(40px);
							}
						}
					}
				}
			}
		}
		&.subscription{
			.po{
				.table_sw{
					.sw_item{
						border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
						&:first-child{
							border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
							border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
						}
					}
				}
				.table_body{
					.body_item{
						tr{
							td{
								padding: vw-mob(10px) vw-mob(10px) vw-mob(10px) vw-mob(20px);
								&.active{
									padding: vw-mob(20px) vw-mob(20px) vw-mob(20px) vw-mob(30px);
								}
							}
						}
					}
				}
			}
			.chess_wrapper{
				padding: 0 vw-mob(10px);
				.box{
					flex-direction: column;
					margin-bottom: vw-mob(40px);
					&.rev{
						flex-direction: column;
					}
					.text_wrap{
						max-width: 100%;
						margin-bottom: vw-mob(20px);
						h2{
							@include vw-font(24, 32, 400);
							margin-bottom: vw-mob(30px);
						}
						p{
							@include vw-font(16, 22, 400);
						}
						ul{
							padding: 0 0 0 vw-mob(20px);
							li{
								@include vw-font(16, 22, 400);
							}
						}
					}
					.bg_image{
						width: 100%;
						height: vw-mob(380px);
					}
					.type_title{
						flex-basis: 100%;
						margin-top: vw-mob(40px);
						h2{
							@include vw-font(24, 32, 400);
						}
					}
				}
			}
		}
		.question_block{
			padding: 0 vw-mob(10px) vw-mob(50px) vw-mob(10px);
			h2.item-title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(10px);
				text-align: center;
				&:after{
					width: vw-mob(25px);
					height: vw-mob(25px);
					top: vw-mob(-25px);
					right: vw-mob(-5px);
				}
			}
			p.key{
				@include vw-font(18, 22, 400);
				margin-bottom: vw-mob(20px);
			}
			a.send{
				padding: 0 vw-mob(40px);
				@include vw-font(18, 40, 400);
			}
		}
		.page_controls{
			padding: vw-mob(50px) vw-mob(10px);
			h2.item-title{
				@include vw-font(24, 32, 400);
				margin-bottom: vw-mob(20px);
			}
			.key{
				p {
					@include vw-font(18, 22, 400);
				}
			}
			ul.side{
				margin-bottom: vw-mob(40px);
				li{
					border-bottom: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					padding: vw-mob(15px) 0;
					&:first-child{
						border-top: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					a{
						@include vw-font(16, 22, 400);
						padding: 0 vw-mob(40px) 0 0; // 50px
						&:before{
							width: vw-mob(20px);
							height: vw-mob(20px);
						}
						&:after{
							width: vw-mob(15px);
							height: vw-mob(15px);
						}
						&:hover{
							width: 100%;
							padding: 0 vw-mob(60px) 0 vw-mob(40px);
						}
					}
				}
			}
		}
		.values{
			padding: 0 vw-mob(10px);
			margin-bottom: vw-mob(40px);
			.subtitle_wrap{
				margin-bottom: vw-mob(40px);
				h2.item_title{
					@include vw-font(24, 32, 400);
				}
				p.key{
					@include vw-font(16, 20, 400);
				}
			}
			.box_wrap{
				margin-bottom: unset;
				.box{
					padding: vw-mob(45px) vw-mob(10px) vw-mob(10px) vw-mob(10px);
					min-height: vw-mob(180px);
					width: calc(50% - 2.666667vw);
					margin-bottom: vw-mob(20px);
					&:nth-last-child(-n+4){
						margin-bottom: vw-mob(20px);
					}
					p.key{
						@include vw-font(16, 20, 400);
					}
					&:before{
						top: vw-mob(10px);
						right: vw-mob(10px);
						width: vw-mob(25px);
						height: vw-mob(25px);
					}
					&:hover::before{
						transform: rotate(360deg);
					}
				}
			}
		}
	}
}