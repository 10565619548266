.modal_bg{
	.question_modal{
		/* Firefox */
		input[type=number] {
			-moz-appearance: textfield;
		}
		position: relative;
		top: 0;
		transform: unset;
		margin: unset;
		width: 100%;
		height: 100%;
		padding: va-mob(30px) vw-mob(10px) vw-mob(20px) va-mob(10px);
		.modal_head{
			display: flex;
			justify-content: flex-end;
			margin-bottom: vw-mob(30px);
			p.headline{
				@include vw-font(28, 40, 400);
			}
			a.del{
				width: vw-mob(30px);
				background-size: vw-mob(25px);
			}
		}
		.modal_body{
			.input_holder{
				p.key{
					@include vw-font(16, 22, 400);
					&.require{
						padding: 0 0 0 vw-mob(10px);
						@include vw-font(16, 22, 400);
						margin-bottom: vw-mob(10px);
					}
				}
				textarea,
				input{
					border: 1px solid $BG_GREY;
					padding: 0 vw-mob(15px);
					@include vw-font(16, 30, 400);
					border-radius: vw-mob(5px);
					margin-bottom: vw-mob(15px);
				}
				textarea{
					height: vw-mob(200px);
					@include vw-font(16, 20, 400);
				}
			}
			margin-bottom: vw-mob(20px);
			&:last-child{
				margin-bottom: unset;
			}
		}
		.bottom_controls{
			a.btn{
				@include vw-font(20, 45, 400);
				padding: 0 vw-mob(15px);
			}

		}
	}
}