@mixin item_title {
  font-size: 48px;
  line-height: 64px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  color: red;
  @include mobile {
    @include vw-font(24, 32, 400);
  }
  @content;
}