footer.main_footer{
	padding: vw-mob(40px) vw-mob(10px);
	.to_top{
		width: vw-mob(30px);
		height: vw-mob(30px);
		bottom: vw-mob(40px);
		right: vw-mob(10px);
		background-size: 45%;
	}
	.white{
		width: vw-mob(130px);
		height: vw-mob(30px);
		margin-bottom: vw-mob(15px);
	}
	.socials_wrap{
		bottom: unset;
		top: vw-mob(230px);
		left: vw-mob(10px);
		width: vw-mob(140px);
	}
	.info_wrap{
		display: flex;
		flex-direction: column;
		.box{
			&.shift{
				padding-top: unset;
			}
			.logo_text{
				@include vw-font(18, 22, 400);
				margin-bottom: vw-mob(20px);
			}
			h3.footer_sub{
				@include vw-font(18, 22, 400);
				margin-bottom: vw-mob(10px);
			}
			ul{
				margin-bottom: vw-mob(20px);
				li{
					a{
						@include vw-font(14, 21, 400);
						margin-bottom: vw-mob(5px);
					}
				}
			}
			.contact_data{
				margin-bottom: vw-mob(40px);
				a.tel{
					@include vw-font(16, 21, 400);
					margin-bottom: unset;
				}
			}
			.info_data{
				padding-top: 110px;
			}
			.subscribe_data{
				margin-bottom: vw-mob(75px);
				p.key{
					@include vw-font(14, 18, 400);
					margin-bottom: vw-mob(15px);
				}
				input{
					width: 100%;
					height: vw-mob(35px);
					@include vw-font(12, 35, 400);
				}
				.arrow{
					width: vw-mob(20px);
					height: vw-mob(35px);
				}
			}
		}
	}
}