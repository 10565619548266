@import "base";
@import "mixins";
@import "blocks";
@import "header";
@import "footer";
@import "menu";
.breadcrumbs{
	padding: 10px 40px;
	background-color: $WHITE;
	ul.main_nav{
		display: flex;
		max-width: 1600px;
		margin: 0 auto;
		li.nav_item{
			position: relative;
			padding: 0 35px 0 0;
			@include font(12, 16, 400);
			color: $T_GREYBD;
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
				background-image: url(../img/icon/ic-arrow2_grey.svg);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: 6px;
				height: 12px;
			}
			&.active{
				color: $FULL_BLACK;
			}
			&:last-child{
				padding: unset;
				&:after{
					content: none;
				}
			}
		}
	}
}
.sales{
	position: relative;
	max-height: 685px;
	padding: 40px;
	margin-bottom: 100px;
	.sales_head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 50px;
		h2.headline{
			@include font(48, 64, 400);
			text-transform: uppercase;
			color: $RED;
		}
		.slide_controls{
			display: flex;
			justify-content: space-between;
			width: 110px;
			a{
				width: 50px;
				height: 50px;
				background-color: $T_BLACK;
				// &.disabled{
				&.swiper-button-disabled{
					background-color: $LN_GREY;
				}
				background-image: url(../img/icon/ic-arrow2_white.svg);
				background-size: 40%;
				background-position: center;
				&.prev{
					transform: rotate(180deg);
				}
			}
		}
	}
	.sales_tail{
		position: absolute;
		width: 385px;
		top: 685px;
		left: calc(50% - 180px);
		display: flex;
		justify-content: center;
	}
	.sales_body{
		padding: 20px 20px;
		display: flex;
		justify-content: space-between;
	}
}
a.arrow{
	position: relative;
	padding: 0 25px 0 0;
	@include font(20, 26, 400);
	color: $RED;
	&:after{
		content: '';
		position: absolute;
		width: 25px;
		height: 26px;
		background-image: url(../img/icon/ic-arrow_red.svg);
		background-repeat: no-repeat;
		background-size: 80%;
		background-position: center;
		top: 0;
		right: 0;
	}
	&.green{
		&::after{
			background-image: url(../img/icon/ic-arrow_green.svg);
		}
	}
}
.item{
	display: block;
	position: relative;
	background-color: $WHITE;
	width: 325px;
	height: 450px;
	margin-right: 20px;
	&:last-child{
		margin-right: unset;
	}
	padding: 20px;
	.flags{
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: baseline;
		span{
			padding: 0 5px;
			border-radius: 65px;
			color: $WHITE;
			margin-bottom: 10px;
			height: 25px;
			@include font(14, 28, 400);
			text-transform: uppercase;
			&:last-child{
				margin-bottom: unset;
			}
			&.top{
				background-color: $T_YELLOW;
			}
			&.sale{
				background-color: $RED;
			}
		}
	}
	.rate{
		position: absolute;
		right: 20px;
		span{
			width: 25px;
			height: 25px;
		}
	}
	.poster{
		width: 100%;
		height: 250px;
		background-image: var(--i-img);
		background-repeat: no-repeat;
		background-size: auto 90%;
		background-position: center;
		margin-bottom: 20px;
	}
	.info{
		margin-bottom: 30px;
		a.key{
			@include font(20, 26, 400);
			display: block
		}
		a.sub{
			@include font(16, 21, 400);
			color: $T_GREYBD;
		}
	}
	.order{
		display: flex;
		justify-content: space-between;
		p.price{
			position: relative;
			@include font(20, 26, 400);
			&[data-prev]:not([data-prev=""]){
				padding: 20px 0 0 0;
				&:after{
					content: attr(data-prev);
					position: absolute;
					@include font(16, 18, 400);
					text-decoration: line-through;
					color: $T_GREYBD;
					top: 0;
					left: 0;
				}
			}
		}
		a.buy{
			background-color: $BG_GREY;
			padding: 0 15px 0 60px;
			background-size: 25px auto;
			background-position: 18px center;
			&.un{
				background-position: 15px center;
			}
			&:after{
				top: 0;
				@include font(20, 52, 400);
			}
		}
	}
	.extra{
		visibility: hidden;
	}
	&:hover{
		z-index: 1;
		box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .28);
		.extra{
			visibility: visible;
			width: calc(100% + 40px);
			box-shadow: 0 25px 20px 0 rgba(0, 0, 0, .28);
			left: -20px;
			display: block;
			position: relative;
			.border{
				width: 100%;
				border-top: 1px solid $LN_GREY;
				margin: 30px 0 20px 0;
			}
			background-color: $WHITE;
			padding: 0 20px 20px 20px;
			display: flex;
			flex-wrap: wrap;
			p.key{
				width: 50%;
				@include font(16, 21, 400);
				text-align: end;
				margin-bottom: 10px;
				&:nth-last-child(-n+2){
					margin-bottom: unset;
				}
				&.alt{
					color: $T_GREYBD;
					text-align: unset;
				}
			}
		}
	}
}
.sales_body{
	.item{
		&:hover{
			height: auto;
			.extra{
				box-shadow: unset;
				padding: 0 20px;
			}
		}
	}
}
.swiper{
	position: relative;
	max-width: 100%;
	.swiper-slide{
		-webkit-backface-visibility: hidden; 
		-webkit-transform: translate3d(0, 0, 0);
	}
	.swiper-pagination{
		position: absolute;
		bottom: 140px;
		.swiper-pagination-bullet{
			width: 10px;
			height: 10px;
			border-radius: 0%;
			background-color: $WHITE;
			opacity: .5;
			margin: 0 10px;
			&-active{
				opacity: 1;
			}
		}
	}
}
.social_resp,
.farming_poster,
.innovations{
	.box_wrap{
		display: flex;
		flex-wrap: wrap;
		.box{
			padding: 40px;
			position: relative;
			border: 2px solid $FULL_BLACK;
			p.item_title{
				@include font(35, 40, 400);
				margin-bottom: 10px;
			}
			.key{
				p {
					@include font(20, 26, 400);
				}
			}
			&::after{
				content: '';
				position: absolute;
				width: 60px;
				height: 40px;
				background-image: url(../img/icon/ic-promo-arrow_red.svg);
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				bottom: 40px;
				left: 40px;
			}
			&.blank{
				border: none;
				&::before,
				&::after{
					content: none;
				}
			}
			&.full{
				flex-basis: 100%;
				border-right: 2px solid $FULL_BLACK;
			}
			&.bg,
			&.red{
				color: $WHITE;
				border: none;
				&::after{
					background-image: url(../img/icon/ic-promo-arrow_white.svg);
				}
			}
			&.red{
				background-color: $RED;
			}
			&.bg{
				background-image: var(--bg-img);
				p,h2{
					position: relative;
					z-index: 1;
				}
				&:before{
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					opacity: .6;
					background-color: $BG_BLACK;
					top: 0;
					left: 0;
				}
			}
			&.noarrow{
				&:after{
					content: none;
				}
			}
		}
		&.w6{
			.box{
				&:nth-child(2),
				&:nth-child(5){
					border: 1px solid transparent;
				}
				&:nth-child(2),
				&:nth-child(3){
					border-bottom: 1px solid $WHITE;
				}
			}
		}
	}
	&.innovations{
		padding: 0 20px;
		margin-bottom: 100px;
		h2.item_title{
			@include font(48, 64, 400);
			text-transform: uppercase;
			text-align: center;
			color: $RED;
			margin-bottom: 40px;
		}
		.key{
			margin-bottom: 70px;
			p{
				@include font(28, 37, 400);
				display: block;
				max-width: 890px;
				margin: 0 auto;
				text-align: center;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 20px;
				}
			}
		}
		.box{
			width: 33.3%;
			height: 330px;
			background-repeat: no-repeat;
			background-size: cover;
			&:after{
				width: 35px;
				height: 35px;
			}
			&.blank.red{
				display: flex;
				align-items: flex-end;
				p.item_title{
					text-transform: uppercase;
				}
			}
		}
	}
}
.block_poster{
	.poster{
		background-image: var(--p-bg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		margin-bottom: 40px;
	}
	div.key{
		h2{
			@include font(32, 37, 600);
			margin-bottom: 20px;
		}
		p{
			@include font(28, 37, 400);
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: unset;
			}
		}
		ul{
			@include font(28, 37, 400);
			li{
				list-style: disc;
				margin-bottom: 10px;
				&:last-child{
					margin-bottom: unset;
				}
			}
		}
	}
	&.wide{
		// padding: 0 40px;
		.poster{
			height: 600px;
		}
		.key{
			padding: 0 40px 100px 500px;
		}
	}
}
.sw_wrap{
	.switch_target{
		.text_wrap{
			display: none;
			position: relative;
			flex-shrink: 0;
			.more{
				position: absolute;
				bottom: 0;
				left: 0;
			}
			span {
				color: $RED;
			}
			&.active{
				display: block;
			}
		}
	}
	.key{
		p {
			@include font(26, 32, 400);
			text-align: center;
			a {
				color: $RED;
				opacity: .5;
				transition: opacity .25s;
				&:hover{
					opacity: 1;
				}
			}
		}
	}
}
.callme_key{
	max-width: 1100px;
	margin: 0 auto;
	p {
		@include font(26, 32, 400);
		text-align: center;
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: unset;
		}
		a {
			color: $RED;
			opacity: .5;
			transition: opacity .25s;
			&:hover{
				opacity: 1;
			}
		}
	}
}
.wrapper{
	max-width: 1600px;
	margin: 0 auto;
	&.main{
		.promo_slider{
			@include promo_slider;
		}
		.partners{
			padding: 40px;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 100px;
			position: relative;
			.content{
				position: absolute;
				width: 100%;
				height: 400px;
				top: 50px;
				left: 0;
				transition: opacity .25s;
				opacity: 0;
				background-color: #fff;
				box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2) inset;
				z-index: -1;
				border-radius: 20px;
				padding: 80px;
				display: flex;
				align-items: center;
				i{
					position: absolute;
					top: 20px;
					right: 20px;
					width: 30px;
					height: 30px;
					background-image: url(../img/icon/ic-close.svg);
					background-repeat: no-repeat;
					background-size: 80%;
					background-position: center;
				}
				.body{
					@include font(24, 26, 400);
				}
				&.front{
					z-index: 1;
				}
			}
			.block-title{
				flex: 0 1 80%; // 3 * 8px
				@include font(48, 80, 400);
				color: $RED;
				text-transform: uppercase;
			}
			.p_item{
				width: 270px;
				height: 100px;
				background-image: var(--pt-bg);
				background-position: center;
				background-repeat: no-repeat;
				flex: 0 1 20%;
				position: relative;
				border: 1px solid transparent;
				transition: all .25s;
				border-radius: 5px;
				cursor: pointer;
				&.wider{
					background-size: 60% 30%;
				}
				&.bs_50_a{
					background-size: 50% auto;
				}
				&.bs_70_a{
					background-size: 70% auto;
				}
				&.bs_a_60{
					background-size: auto 60%;
				}
				&.bs_a_100{
					background-size: auto 100%;
				}
				&::before,
				&::after{
					content: '';
					background-color: $T_GREYB5;
					width: 1px;
					height: 75px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					transition: all .05s;
				}
				&:after{
					right: -2px;
				}
				&:before{
					left: -1px;
				}
				&:hover{
					transform: scale(.99);
					box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2) inset;
					&::after,
					&::before{
						opacity: 0;
					}
				}
			}
		}
		.crop_field{
			display: flex;
			justify-content: space-between;
			padding: 0 40px;
			margin-bottom: 100px;
			.part{
				&.left{
					width: 400px;
					flex-shrink: 0;
					h2.headline{
						@include font(48, 54, 400);
						color: $RED;
						margin-bottom: 20px;
					}
					p.key{
						@include font(24, 32, 400);
					}
				}
				&.right{
					min-width: 860px;
					max-width: 900px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-around;
					flex-grow: 0;
					flex-shrink: 0;
					.block_item{
						position: relative;
						min-width: 400px;
						max-width: 430px;
						height: 240px;
						background-color: $BG_GREY;
						color: $FULL_BLACK;
						padding: 75px 30px 30px 30px;
						margin: 0 20px 20px 0;
						@include font(20, 26, 400);
						&:nth-child(even){
							margin-right: unset;
						}
						&:nth-last-child(-n+2){
							margin-bottom: unset;
						}
						&:after{
							content: '';
							background-image: url(../img/4-leaf-rev.svg);
							background-repeat: no-repeat;
							background-size: contain;
							width: 40px;
							height: 40px;
							position: absolute;
							top: 20px;
							right: 20px;
							transition: all .5s;
						}
						&.active{
							background-color: $RED;
							color: $WHITE;
							&:after{
								background-image: url(../img/4-leaf.svg);
							}
						}
						&:hover{
							transform: scale(1.01);
							&:after{
								transform: rotate(360deg);
							}
						}
					}
				}
			}
		}
		.agrar_solutions{
			padding: 100px 40px;
			background-color: $BG_GREY;
			position: relative;
			// margin-bottom: 100px;
			h2.subtitle{
				@include font(48, 64, 400);
				text-transform: uppercase;
				margin-bottom: 20px;
			}
			p.key{
				@include font(20, 26, 400); // 100 - 26 - 20
				margin-bottom: 54px;
			}
			.bg_image{
				background-image: var(--bg-img);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: calc(100% - 480px);
				height: 425px;
				margin-left: 80px;
				flex-shrink: 0;
			}
			.stage_wrap{
				display: flex;
				justify-content: flex-end;
				margin-bottom: 40px;
				.text_wrap{
					width: 400px;
				}
			}
			.box{
				display: flex;
				flex-wrap: wrap;
				.agrar_item{
					border: 2px solid $FULL_BLACK;
					border-radius: 75px;
					@include font(32, 77, 400);
					margin: 0 20px 20px 0;
					padding: 0 40px;
					transition: all .15s;
					position: relative;
					cursor: pointer;
					&:after{
						content: '';
						width: 15px;
						height: 15px;
						border-radius: 50%;
						background-color: $RED;
						opacity: 0;
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
						transition: all .5s;
					}
					&.active,
					&:hover{
						background-color: $FULL_BLACK;
						color: $WHITE;
						&:after{
							opacity: 1;
						}
					}
				}
			}
		}
		// .main_news{
		// 	padding: 0 40px 30px 40px;
		// 	display: flex;
		// 	flex-wrap: wrap;
		// 	align-items: flex-end;
		// 	position: relative;
		// 	margin-bottom: 100px;
		// 	h2.item_title{
		// 		@include font(48, 50, 400);
		// 		text-transform: uppercase;
		// 		margin-bottom: 40px;
		// 		flex-basis: 100%;
		// 	}
		// 	.alt_news{
		// 		flex-shrink: 0;
		// 		width: 400px;
		// 		padding: 40px 0;
		// 		margin-bottom: 60px;
		// 		.alt_item{
		// 			p.subtitle{
		// 				@include font(28, 40, 400);
		// 				margin-bottom: 10px;
		// 			}
		// 			p.key{
		// 				@include font(20, 26, 400);
		// 			}
		// 		}
		// 	}
		// 	.main_news_gal{
		// 		position: relative;
		// 		padding: 40px 0;
		// 		width: 920px;
		// 		margin-bottom: 60px;
		// 		.gal_wrapper{
		// 			height: 500px;
		// 			display: flex;
		// 			align-items: center;
		// 			margin-left: -200px; // slider shift fix
		// 		}
		// 		.alt_item{
		// 			flex-shrink: 0;
		// 			width: 520px; // keep wide to avoid shifting
		// 			height: 345px;
		// 			background-image: var(--n-bg);
		// 			background-position: center;
		// 			background-size: cover;
		// 			background-repeat: no-repeat;
		// 			transition: all .25s;
		// 			&.swiper-slide-active{
		// 				width: 520px;
		// 				height: 500px;
		// 			}
		// 		}
		// 		.news_pagination{
		// 			width: 520px;
		// 			height: 2px;
		// 			background: $T_GREYB5;
		// 			top: unset;
		// 			bottom: 0;
		// 			left: 0;
		// 			.swiper-pagination-progressbar-fill{
		// 				background: $FULL_BLACK;
		// 			}
		// 		}
		// 		.gal_controls{
		// 			position: absolute;
		// 			bottom: 50px;
		// 			right: 280px;
		// 			width: 80px;
		// 			height: 20px;
		// 			display: flex;
		// 			justify-content: space-between;
		// 			z-index: 1;
		// 			a.btn{
		// 				width: 30px;
		// 				height: 20px;
		// 				background-image: url(../img/icon/ic-arrow_red.svg);
		// 				background-size: cover;
		// 				background-position: center;
		// 				background-repeat: no-repeat;
		// 				&.prev{
		// 					transform: rotate(180deg);
		// 				}
		// 			}
		// 		}
		// 	}
		// 	.news_tail{
		// 		position: absolute;
		// 		width: 220px;
		// 		bottom: 0;
		// 		left: calc(50% - 110px);
		// 		display: flex;
		// 		justify-content: center;
		// 		a.arrow{
		// 			position: relative;
		// 			padding: 0 25px 0 0;
		// 			@include font(20, 26, 400);
		// 			color: $RED;
		// 			&:after{
		// 				content: '';
		// 				position: absolute;
		// 				width: 25px;
		// 				height: 26px;
		// 				background-image: url(../img/icon/ic-arrow_red.svg);
		// 				background-repeat: no-repeat;
		// 				background-size: 80%;
		// 				background-position: center;
		// 				top: 0;
		// 				right: 0;
		// 			}
		// 		}
		// 	}
		// }
		.page_controls{
			display: flex;
			justify-content: space-between;
			padding: 100px 40px;
			background-color: $BG_GREY;
			ul.side{
				flex-shrink: 0;
				width: calc(50% - 40px);
				li{
					border-bottom: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					&:first-child{
						border-top: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					a{
						display: block;
						width: fit-content;
						transition: all .25s;
						@include font(24, 100, 400);
						position: relative;
						padding: 0 100px 0 0; // 50px
						text-transform: uppercase;
						&:before{
							content: '';
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: $RED;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: 0;
							opacity: 0;
						}
						&:after{
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 30px;
							height: 30px;
							background-image: url(../img/icon/ic-arrow.svg);
							background-repeat: no-repeat;
							background-size: contain;
							transition: all .25s;
						}
						&:hover{
							width: 100%;
							padding: 0 100px 0 60px;
							color: $RED;
							&:after{
								background-image: url(../img/icon/ic-arrow_red.svg);
							}
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.question_block{
			padding: 150px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			h2.item-title{
				@include font(48, 50, 400);
				position: relative;
				width: fit-content;
				text-transform: uppercase;
				margin-bottom: 20px;
				&:after{
					content: '';
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background-color: $RED;
					position: absolute;
					top: -25px;
					right: -70px;
				}
			}
			p.key{
				@include font(20, 50, 400);
				margin-bottom: 50px;
			}
			a.send{
				padding: 0 70px;
				background-color: $RED;
				color: $WHITE;
				@include font(20, 65, 400);
				position: relative;
			}
		}
	}
	&.about_company{
		.mission{
			background-color: $BG_GREY;
			padding: 100px 40px;
			margin-bottom: 100px;
			display: flex;
			.part{
				&.left{
					width: 500px;
					flex-shrink: 0;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					.round{
						width: 190px;
						height: 190px;
						border-radius: 50%;
						border: 2px solid $FULL_BLACK;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						&:first-child{
							// flex-basis: 100%;
							margin: 0 100px -20px 100px;
						}
						&:not(:first-child){
							margin: 0 7px;
						}
						p.info{
							position: relative;
							width: fit-content;
							text-transform: uppercase;
							@include font(30, 40, 400);
							// &:after{
							// 	content: '';
							// 	width: 15px;
							// 	height: 15px;
							// 	border-radius: 50%;
							// 	background-color: $FULL_BLACK;
							// 	position: absolute;
							// 	bottom: -20px;
							// 	right: -5px;
							// 	opacity: 0;
							// 	transition: opacity .25s;
							// }
						}
						&.active,
						&:hover{
							background-color: $RED;
							p.info{
								color: $WHITE;
								// &:after{
								// 	opacity: 1;
								// }
							}
						}
					}
				}
				&.right{
					.text_wrap{
						display: none;
						&.active{
							display: block;
						}
					}
					.key{
						p{
							@include font(28, 37, 400)
						}
					}
				}
			}
		}
		.values{
			@include values_block;
		}
		.agrocoach{
			@include agrocoach_block;
		}
		.social_resp{
			padding: 0 40px;
			margin-bottom: 100px;
			h2.item_title{
				@include font(48, 50, 400);
				color: $RED;
				margin-bottom: 40px;
			}
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				.box{
					padding: 40px;
					height: 330px;
					&:not(.full),
					&:not(.half2){
						flex-basis: calc(100% / 3);
						&:nth-child(4){
							border-top: unset;
							border-right: unset;
						}
					}
					&.half2{
						flex-basis: calc( (100%/3) * 2 );
						margin-left: auto;
						border: unset;
						&:after{
							content: none;
						}
					}
				}
			}
		}
		.library{
			padding: 100px 40px 40px 40px;
			margin-bottom: 90px;
			background-color: $BG_GREY;
			position: relative;
			h2.item_title{
				@include font(48, 50, 400);
				text-transform: uppercase;
				color: $RED;
				margin-bottom: 80px;
			}
			.main_video_gal{
				position: relative;
				margin-bottom: 50px;
				padding: 0 0 40px 0;
				.gal_wrapper{
					height: 500px;
					display: flex;
					margin-left: -380px; // slider shift fix
				}
				.alt_item{
					flex-shrink: 0;
					width: 400px; // keep wide to avoid shifting
					height: 280px;
					transition: all .25s;
					&.swiper-slide-active{
						width: 890px;
						height: 500px;
					}
					iframe{
						width: 100%;
						height: 100%;
					}
				}
				.gal_pagination{
					width: 520px;
					height: 2px;
					background: $T_GREYB5;
					top: unset;
					bottom: 0;
					left: 15px;
					.swiper-pagination-progressbar-fill{
						background: $FULL_BLACK;
					}
				}
				.gal_controls{
					position: absolute;
					bottom: 50px;
					right: 280px;
					width: 80px;
					height: 20px;
					display: flex;
					justify-content: space-between;
					z-index: 1;
					a.btn{
						width: 30px;
						height: 20px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						&.prev{
							transform: rotate(180deg);
						}
					}
				}
			}
			.news_tail{
				position: absolute;
				width: 220px;
				bottom: 20px;
				left: calc(50% - 110px);
				display: flex;
				justify-content: center;
				a.arrow{
					position: relative;
					padding: 0 25px 0 0;
					@include font(20, 26, 400);
					color: $RED;
					&:after{
						content: '';
						position: absolute;
						width: 25px;
						height: 26px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-repeat: no-repeat;
						background-size: 80%;
						background-position: center;
						top: 0;
						right: 0;
					}
				}
			}
		}
		.learn{
			margin-bottom: 100px;
			.headline{
				margin: 0 auto;
				width: 890px;
				margin-bottom: 80px;
				h2{
					@include font(48, 65, 400);
					text-transform: uppercase;
					text-align: center;
					margin-bottom: 40px;
				}
				p{
					@include font(28, 38, 400);
					text-align: center;
					margin-bottom: 10px;
					&:last-child{
						margin-bottom: unset;
					}
				}
			}
			.box_wrap{
				padding: 0 40px;
				display: flex;
				justify-content: space-between;
				.box{
					width: calc(50% - 40px);
					height: 500px;
					padding: 250px 40px 40px 40px;
					position: relative;
					p.item_title{
						@include font(32, 42, 400);
						text-transform: uppercase;
						max-width: 360px;
					}
					&::after{
						content: '';
						position: absolute;
						width: 35px;
						height: 35px;
						background-image: url(../img/icon/ic-promo-arrow_white.svg);
						background-position: center;
						background-size: contain;
						background-repeat: no-repeat;
						bottom: 40px;
						left: 40px;
					}
					&.bg{
						background-image: var(--bg-img);
						background-size: cover;
						background-repeat: no-repeat;
						color: $WHITE;
						p,h2{
							position: relative;
							z-index: 1;
						}
						&:before{
							content: '';
							position: absolute;
							width: 100%;
							height: 100%;
							opacity: .6;
							background-color: $BG_BLACK;
							top: 0;
							left: 0;
						}
					}
				}
			}
		}
		// .blog{
		// 	// padding: 0 40px;
		// 	margin-bottom: 60px;
		// 	padding: 0 40px 60px 40px;
		// 	position: relative;
		// 	.headline{
		// 		display: flex;
		// 		justify-content: space-between;
		// 		margin-bottom: 40px;
		// 		h2{
		// 			@include font(48, 50, 400);
		// 			text-transform: uppercase;
		// 		}
		// 		a.btn{
		// 			@include font(22, 50, 400);
		// 			color: $WHITE;
		// 			background-color: $RED;
		// 			padding: 0 15px;
		// 		}
		// 	}
		// 	.service_tail{
		// 		position: absolute;
		// 		bottom: 0;
		// 		display: flex;
		// 		justify-content: center;
		// 		width: 100%;
		// 	}
		// 	.box_wrap{
		// 		display: flex;
		// 		flex-wrap: wrap;
		// 		justify-content: space-between;
		// 		.box{
		// 			&:nth-child(n+7){
		// 				display: none;
		// 			}
		// 			background-image: var(--bg-img);
		// 			background-repeat: no-repeat;
		// 			background-size: cover;
		// 			background-position: center;
		// 			width: 400px;
		// 			height: 330px;
		// 			position: relative;
		// 			margin-bottom: 40px;
		// 			&:nth-last-child(-n+3){
		// 				margin-bottom: unset;
		// 			}
		// 			.alt_box{
		// 				height: 90px;
		// 				width: 100%;
		// 				background-color: $T_BLACK;
		// 				color: $WHITE;
		// 				position: absolute;
		// 				bottom: 0;
		// 				display: flex;
		// 				align-items: center;
		// 				p{
		// 					@include font(16, 20, 400);
		// 					text-transform: uppercase;
		// 					padding: 0 95px 0 20px;
		// 					position: relative;
		// 					&::after{
		// 						content: '';
		// 						position: absolute;
		// 						width: 35px;
		// 						height: 35px;
		// 						background-image: url(../img/icon/ic-promo-arrow_white.svg);
		// 						background-position: center;
		// 						background-size: contain;
		// 						background-repeat: no-repeat;
		// 						top: 50%;
		// 						transform: translateY(-50%);
		// 						right: 20px;
		// 						opacity: 0;
		// 						transition: opacity .25s;
		// 					}
		// 				}
		// 				&:hover{
		// 					p::after{
		// 						opacity: 1;
		// 					}
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}
	&.farming{
		.farming_poster{
			padding: 0 40px;
			margin-bottom: 100px;
			.box_wrap{
				width: 100%;
				height: 590px;
				background-image: var(--p-bg);
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 40px;
				.box{
					flex-basis: calc(100% / 3);
					border-right: 1px solid $WHITE;
					border-left: 1px solid $WHITE;
					&:nth-child(odd){
						// border: 1px solid transparent;
						border: unset;
					}
				}
			}
			p.farming_alt{
				@include font(28, 37, 400);
				padding: 0 0 0 480px;
			}
		}
		.pack_services{
			padding: 0 40px 70px 40px;
			margin-bottom: 100px;
			position: relative;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				margin-bottom: 40px;
				color: $BG_BLACK;
			}
			.service_tail{
				position: absolute;
				bottom: 0;
				display: flex;
				justify-content: center;
				width: 100%;
			}
			.service_box{
				display: flex;
				justify-content: space-between;
				.box_item{
					width: 700px;
					height: 420px;
					.image{
						width: 100%;
						height: 330px;
						background-color: var(--bg-color);
						background-image: var(--s-img);
						background-repeat: no-repeat;
						// background-size: 70% auto;
						background-size: cover;
						background-position: center;
					}
					p.info{
						background-color: $BG_BLACK;
						@include font(20, 26, 400);
						padding: 20px 80px 20px 20px;
						color: $WHITE;
						text-transform: uppercase;
						position: relative;
						cursor: pointer;
						&:before,
						&:after{
							content: '';
							position: absolute;
							opacity: 0;
							transition: opacity .25s;
						}
						&:before{
							width: 20px;
							height: 20px;
							top: 50%;
							transform: translateY(-50%);
							right: 20px;
							background-image: url(../img/icon/ic-promo-arrow_white.svg);
							background-repeat: no-repeat;
							background-size: contain;
						}
						&:hover{
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.irrigation{
			padding: 100px 40px;
			background-color: $BG_BLACK;
			display: flex;
			justify-content: space-between;
			color: $WHITE;
			margin-bottom: 100px;
			.side{
				&.left{
					width: 400px;
					padding: 0 0 30px 0;
					position: relative;
					a.arrow{
						color: #ff2c2c;
						position: absolute;
						bottom: 0;
					}
					h2.item_title{
						@include font(48, 50, 400);
						text-transform: uppercase;
						margin-bottom: 40px;
					}
					p.key{
						@include font(20, 26, 400);
					}
				}
				&.right{
					width: 870px;
				}
			}
			.poster{
				height: 580px;
				background-color: var(--bg-color);
				background-image: var(--bg-img);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}
		}
		.po{
			@include po_block;
		}
		.question_block{
			padding: 50px 0 100px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			h2.item-title{
				@include font(48, 50, 400);
				position: relative;
				width: fit-content;
				text-transform: uppercase;
				margin-bottom: 20px;
				&:after{
					content: '';
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background-color: $RED;
					position: absolute;
					top: -25px;
					right: -70px;
				}
			}
			p.key{
				@include font(20, 50, 400);
				margin-bottom: 50px;
			}
			a.send{
				padding: 0 70px;
				background-color: $RED;
				color: $WHITE;
				@include font(20, 65, 400);
				position: relative;
			}
		}
	}
	&.machinery{
		.agrar_solutions{
			padding: 100px 40px;
			background-color: $BG_GREY;
			position: relative;
			margin-bottom: 100px;
			// display: flex;
			// align-items: flex-end;
			.stage_wrap{
				display: flex;
				justify-content: flex-end;
				margin-bottom: 40px;
				.text_wrap{
					position: relative;
					width: calc(100% - 880px);
					margin-right: 80px;
					h2.subtitle{
						@include font(48, 64, 400);
						text-transform: uppercase;
						margin-bottom: 20px;
					}
					p.key{
						@include font(20, 26, 400);
					}
				}
			}
			.bg_image{
				background-image: var(--bg-img);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: 800px;
				height: 400px;
			}
			.box{
				display: flex;
				flex-wrap: wrap;
				.agrar_item{
					border: 2px solid $FULL_BLACK;
					border-radius: 75px;
					@include font(32, 77, 400);
					margin: 0 20px 20px 0;
					padding: 0 40px;
					transition: all .15s;
					position: relative;
					cursor: pointer;
					&:after{
						content: '';
						width: 15px;
						height: 15px;
						border-radius: 50%;
						background-color: $RED;
						opacity: 0;
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
						transition: all .5s;
					}
					&:hover{
						background-color: $FULL_BLACK;
						color: $WHITE;
						&:after{
							opacity: 1;
						}
					}
				}
			}
		}
		// .fin_progs{
		// 	padding: 0 40px;
		// 	margin-bottom: 100px;
		// 	.headline{
		// 		display: flex;
		// 		justify-content: space-between;
		// 		margin-bottom: 50px;
		// 		h2{
		// 			@include font(48, 64, 400);
		// 			text-transform: uppercase;
		// 			color: $RED;
		// 			width: 400px;
		// 		}
		// 		p {
		// 			@include font(20, 26, 400);
		// 			color: $FULL_BLACK;
		// 			width: calc(100% - 480px);
		// 		}
		// 	}
		// 	.partners_box{
		// 		display: flex;
		// 		flex-wrap: wrap;
		// 		justify-content: space-between;
		// 		margin-bottom: 50px;
		// 		.box{
		// 			width: 400px;
		// 			height: 150px;
		// 			background-color: $WHITE;
		// 			background-image: var(--p-img);
		// 			background-repeat: no-repeat;
		// 			background-size: 50%;
		// 			background-position: center;
		// 			border: 5px solid transparent;
		// 			margin-bottom: 40px;
		// 			transition: all .25s;
		// 			cursor: pointer;
		// 			&:nth-last-child(-n+3){
		// 				margin-bottom: unset;
		// 			}
		// 			&:hover{
		// 				border-top-color: $RED;
		// 				border-right-color: $RED;
		// 			}
		// 		}
		// 	}
		// 	a.red{
		// 		background-color: $RED;
		// 		color: $WHITE;
		// 		padding: 0 25px;
		// 		@include font(20, 70, 400);
		// 		width: fit-content;
		// 		margin: 0 auto;
		// 	}
		// }
	}
	&.agroculture{
		.agrar_solutions{
			padding: 150px 40px;
			background-color: $BG_GREY;
			position: relative;
			margin-bottom: 100px;
			.stage_wrap{
				display: flex;
				justify-content: flex-end;
				margin-bottom: 50px;
				.text_wrap{
					padding: 0 0 40px 0;
				}
			}
			.alt{
				width: 440px;
				h2{
					@include font(48, 64, 400);
					text-transform: uppercase;
					margin-bottom: 20px;
				}
				p{
					@include font(20, 26, 400);
					&.upper{
						text-transform: uppercase;
					}
					span{
						text-transform: none;
						color: $T_BLACK;
					}
				}
			}
			.bg_image{
				background-image: var(--bg-img);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center bottom;
				width: calc(100% - 520px);
				height: 425px;
				margin-left: 80px;
			}
			.box{
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 60px;
				.agrar_item{
					border: 2px solid $FULL_BLACK;
					border-radius: 75px;
					@include font(32, 77, 400);
					text-transform: uppercase;
					margin: 0 20px 20px 0;
					padding: 0 40px;
					transition: all .15s;
					position: relative;
					cursor: pointer;
					&:after{
						content: '';
						width: 15px;
						height: 15px;
						border-radius: 50%;
						background-color: $RED;
						opacity: 0;
						position: absolute;
						right: 15px;
						top: 50%;
						transform: translateY(-50%);
						transition: all .5s;
					}
					&:hover{
						background-color: $FULL_BLACK;
						color: $WHITE;
						&:after{
							opacity: 1;
						}
					}
				}
			}
		}
		.fertilizers{
			padding: 0 40px;
			margin-bottom: 100px;
			.headline{
				h2{
					@include font(48, 64, 400);
					text-align: center;
					text-transform: uppercase;
					color: $RED;
					margin-bottom: 40px;
				}
				p{
					@include font(28, 37, 400);
					max-width: 890px;
					margin: 0 auto;
					margin-bottom: 80px;
				}
			}
			.farming_poster{
				.box_wrap{
					width: 100%;
					height: 590px;
					background-image: var(--p-bg);
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					margin-bottom: 40px;
					.box{
						flex-basis: calc(100% / 4);
						border-right: 1px solid $WHITE;
						border-left: 1px solid $WHITE;
						padding-top: 140px;
						&:nth-child(odd){
							border: 1px solid transparent;
						}
					}
				}
				// p.farming_alt{
				// 	@include font(28, 37, 400);
				// 	padding: 0 0 0 480px;
				// }
				.btn_wrap{
					display: flex;
					justify-content: center;
					a{
						@include font(24, 50, 400);
						background-color: $RED;
						color: $WHITE;
						padding: 0 20px;
					}
				}
			}
		}
		.bio{
			@include bio_block;
		}
		&.item_culture{
			.block_poster{
				margin-bottom: 100px;
			}
			.fertilizers{
				p{
					max-width: 1100px;
					margin-bottom: 40px;
				}
				.key{
					p {
						margin: 0 auto;
						@include font(26, 32, 400);
						text-align: center;
						a {
							color: $RED;
							opacity: .5;
							transition: opacity .25s;
							&:hover{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&.fertilizers-page{
			.fertilizers{
				padding: 40px 40px 0 40px;
			}
			article.group_wrapper{
				padding: 0 40px;
				section.group_wrap{
					.section_name{
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 40px;
						.poster{
							width: 270px;
							height: 100px;
							background-image: var(--p-img);
							background-repeat: no-repeat;
							background-position: center;
						}
						.key{
							@include font(18, 22, 400);
							width: calc(100% - 300px);
							p{
								margin-bottom: 10px;
								&:last-child{
									margin-bottom: unset;
								}
							}
						}
					}
					.tags_head{
						padding: 20px 0;
						display: flex;
						justify-content: center;
						.tag{
							padding: 0 20px 0 15px;
							margin: 0 5px;
							height: 40px;
							border-radius: 50px;
							border: 2px solid $FULL_BLACK;
							@include font(20, 37, 400);
							position: relative;
							cursor: pointer;
							&:after{
								content: '';
								width: 10px;
								height: 10px;
								border-radius: 50%;
								background-color: $FULL_BLACK;
								opacity: 0;
								position: absolute;
								right: 7px;
								top: 50%;
								transform: translateY(-50%);
								transition: all .5s;
							}
							&.active,
							&:hover{
								border-color: $RED;
								background-color: $RED;
								color: $WHITE;
								&:after{
									opacity: 1;
								}
							}
						}
					}
					.wrapper_controls{
						position: relative;
						.btn{
							position: absolute;
							width: 50px;
							height: 50px;
							border-radius: 50%;
							background-image: url(../img/icon/ic-arrow3.svg);
							background-position: center;
							background-repeat: no-repeat;
							background-size: 50% auto;
							transition: background-color .15s;
							z-index: 2;
							top: 220px;
							&:hover{
								background-color: $T_GREYBD;
							}
							&.next{
								left: calc(100% - 50px);
								transform: rotate(180deg);
							}
						}
					}
					.items_wrap{
						margin-bottom: 100px;
						.item_fertilizer{
							width: 100%;
							height: 500px;
							display: flex;
							justify-content: space-between;
							background-color: $WHITE;
							.side{
								&.image{
									width: 500px;
									height: 100%;
									background-image: var(--b-img);
									background-repeat: no-repeat;
									background-size: contain;
									background-position: center;
								}
								&.info{
									width: calc(100% - 520px);
									padding: 40px 40px 40px 0;
									display: flex;
									flex-direction: column;
									justify-content: center;
									h3{
										@include font(32, 36, 400);
										margin-bottom: 20px;
									}
									.description{
										@include font(18, 22, 400);
										p{
											margin-bottom: 10px;
											&:last-child{
												margin-bottom: unset;
											}
										}
										ul {
											margin-bottom: 10px;
											padding: 0 0 0 20px;
											li{
												margin-bottom: 10px;
												&:last-child{
													margin-bottom: unset;
												}
												list-style: disc;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.question_block{
				padding: 50px 0 100px 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				h2.item-title{
					@include font(48, 50, 400);
					position: relative;
					width: fit-content;
					text-transform: uppercase;
					margin-bottom: 20px;
					&:after{
						content: '';
						width: 50px;
						height: 50px;
						border-radius: 50%;
						background-color: $RED;
						position: absolute;
						top: -25px;
						right: -70px;
					}
				}
				p.key{
					@include font(20, 50, 400);
					margin-bottom: 50px;
				}
				a.send{
					padding: 0 70px;
					background-color: $RED;
					color: $WHITE;
					@include font(20, 65, 400);
					position: relative;
				}
			}
		}
	}
	&.agroinfo{
		.block_poster{
			// margin-bottom: 100px;
			.poster{
				margin-bottom: unset;
			}
		}
		
		.agrospace{
			background-color: $BG_GREY;
			padding: 150px 40px 150px 40px;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			position: relative;
			margin-bottom: 100px;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				color: $RED;
				position: absolute; // FIXIT!!!!!
				width: 400px;
				top: 150px;
				left: 40px;
			}
			.alt_news{
				flex-shrink: 0;
				width: 400px;
				padding: 40px 0;
				margin-bottom: 60px;
				.alt_item{
					p.subtitle{
						@include font(28, 40, 400);
						margin-bottom: 10px;
					}
					p.key{
						@include font(20, 26, 400);
					}
				}
			}
			.main_news_gal{
				position: relative;
				padding: 40px 0;
				width: 920px;
				margin-bottom: 60px;
				.gal_wrapper{
					height: 500px;
					display: flex;
					align-items: center;
					margin-left: -200px; // slider shift fix
				}
				.alt_item{
					flex-shrink: 0;
					width: 520px; // keep wide to avoid shifting
					height: 345px;
					background-color: $WHITE;
					background-image: var(--n-bg);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					transition: all .25s;
					&.swiper-slide-active{
						width: 520px;
						height: 500px;
					}
				}
				.news_pagination{
					width: 520px;
					height: 2px;
					background: $T_GREYB5;
					top: unset;
					bottom: 0;
					left: 0;
					.swiper-pagination-progressbar-fill{
						background: $FULL_BLACK;
					}
				}
				.gal_controls{
					position: absolute;
					bottom: 50px;
					right: 280px;
					width: 80px;
					height: 20px;
					display: flex;
					justify-content: space-between;
					z-index: 1;
					a.btn{
						width: 30px;
						height: 20px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						&.prev{
							transform: rotate(180deg);
						}
					}
				}
			}
			.news_tail{
				position: absolute;
				width: 220px;
				bottom: 100px;
				left: calc(50% - 110px);
				display: flex;
				justify-content: center;
				a.arrow{
					position: relative;
					padding: 0 25px 0 0;
					@include font(20, 26, 400);
					color: $RED;
					&:after{
						content: '';
						position: absolute;
						width: 25px;
						height: 26px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-repeat: no-repeat;
						background-size: 80%;
						background-position: center;
						top: 0;
						right: 0;
					}
				}
			}
		}
		.blog{
			padding: 0 40px;
			margin-bottom: 100px;
			.headline{
				display: flex;
				justify-content: space-between;
				margin-bottom: 40px;
				h2{
					@include font(48, 50, 400);
					text-transform: uppercase;
				}
				a.btn{
					@include font(22, 50, 400);
					color: $WHITE;
					background-color: $RED;
					padding: 0 15px;
				}
			}
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.box{
					background-image: var(--bg-img);
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					width: 400px;
					height: 330px;
					position: relative;
					margin-bottom: 40px;
					&:nth-last-child(-n+3){
						margin-bottom: unset;
					}
					.alt_box{
						height: 90px;
						width: 100%;
						background-color: $T_BLACK;
						color: $WHITE;
						position: absolute;
						bottom: 0;
						display: flex;
						align-items: center;
						p{
							@include font(16, 20, 400);
							text-transform: uppercase;
							padding: 0 95px 0 20px;
							position: relative;
							&::after{
								content: '';
								position: absolute;
								width: 35px;
								height: 35px;
								background-image: url(../img/icon/ic-promo-arrow_white.svg);
								background-position: center;
								background-size: contain;
								background-repeat: no-repeat;
								top: 50%;
								transform: translateY(-50%);
								right: 20px;
								opacity: 0;
								transition: opacity .25s;
							}
						}
						&:hover{
							p::after{
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	&.map{
		padding: 0 40px;
		.map_wrapper{
			height: 570px;
			#map{
				height: inherit;
			}
		}
		section.full_info{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			min-height: 740px;
			padding: 30px 0 0 0;
			h2.table_head{
				display: none;
				flex-basis: 100%;
				flex-shrink: 0;
				@include font(20, 27, 400);
				text-transform: uppercase;
				text-align: right;
				padding: 30px 30px 30px 0;
				position: relative;
				&:after{
					content: '';
					position: absolute;
					right: 10px;
					top: 35px;
					width: 8px;
					height: 15px;
					background-image: url(../img/icon/ic-arrow3.svg);
					background-size: 350%;
					background-position: center;
					background-repeat: no-repeat;
					transform: rotate(270deg);
					transition: rotate .25s;
				}
				ul.table_filter{
					position: absolute;
					bottom: -100%;
					right: 0;
					padding: 10px 30px 10px 10px;
					border: 1px solid $LN_GREY;
					background-color: $WHITE;
					display: none;
					li{
						cursor: pointer;
					}
				}
			}
			.table_sw{
				flex-shrink: 0;
				width: 400px;
				// .sw_item{
				// 	cursor: pointer;
				// 	border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
				// 	&:first-child{
				// 		border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
				// 	}
				// 	padding: 30px 0;
				// 	p.key{
				// 		@include font(24, 32, 400);
				// 		text-transform: uppercase;
				// 	}
				// 	&.active{
				// 		color: $RED;
				// 		p.key{
				// 			padding: 0 0 0 60px;
				// 			position: relative;
				// 			&:before{
				// 				content: '';
				// 				position: absolute;
				// 				width: 40px;
				// 				height: 40px;
				// 				border-radius: 50%;
				// 				background-color: $RED;
				// 				top: 50%;
				// 				transform: translateY(-50%);
				// 				left: 0;
				// 			}
				// 		}
				// 	}
				// }
				.alt_info{
					padding: 20px 0;
					// margin: 20px 0;
					border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					h2{
						@include font(24, 32, 400);
						text-transform: uppercase;
						padding: 0 0 0 40px;
						position: relative;
						&:before{
							content: '';
							position: absolute;
							width: 30px;
							height: 30px;
							border-radius: 50%;
							background-color: $RED;
							top: 50%;
							transform: translateY(-50%);
							left: 0;
						}
					}
				}
				#map_head{
					width: 100%;
					@include font(24, 40, 400);
				}
				.select2-container--default{
					.select2-selection--single{
						border: unset;
						background-color: $BG_WHITE;
						.select2-selection__rendered{
							padding-left: unset;
						}
						.select2-selection__arrow{
							b{
								border-color: $RED transparent transparent transparent;
							}
						}
					}
				}
			}
			.table_body{
				width: 100%;
			}
			.body_item{
				display: none;
				&.active{
					display: block;
				}
			}
			.table_body{
				flex-shrink: 0;
				max-width: calc(100% - 480px);
				margin-bottom: 100px;
				.part_data{
					border-top: 2px solid $FULL_BLACK;
					.part_head{
						padding: 20px 0 10px 0;
						@include font(32, 42, 400);
						text-transform: uppercase;
					}
					.part_body{
						padding: 0 0 80px 0;
						a{
							display: block;
							@include font(20, 26, 400);
							margin-bottom: 15px;
							color: $RED;
							padding: 0 0 0 35px;
						}
						p{
							@include font(20, 26, 400);
							margin-bottom: 15px;
							&.ico.point{
								position: relative;
								padding: 0 0 0 35px;
								&:before{
									content: '';
									width: 15px;
									height: 20px;
									position: absolute;
									top: 0;
									left: 10px;
									background-image: url(../img/icon/ic-map-mark.svg);
									background-repeat: no-repeat;
									background-size: contain;
									background-repeat: no-repeat;
								}
							}
							&:last-child{
								margin-bottom: unset;
							}
							a{
								display: inline;
								padding: unset;
							}
						}
					}
				}
			}
		}
	}
	&.catalog{
		padding: 0 40px 0 40px;
		.catalog_body{
			display: flex;
			justify-content: space-between;
		}
		.catalog_head{
			margin-bottom: 40px;
			.block_iamge{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 40px;
				.poster{
					width: 760px;
					height: 420px;
					background-image: var(--bg-img);
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				.alt{
					width: calc(100% - 840px);
					p{
						@include font(20, 26, 400);
						margin-bottom: 5px;
						&:last-child{
							margin-bottom: unset;
						}
						&.bolder{
							font-weight: 700;
						}
					}
					a.margin{
						margin-top: 80px;
						width: fit-content;
						display: block;
						padding: 0 15px;
						@include font(18, 50, 400);
						background-color: var(--promo-color);
						color: $WHITE;
					}
					
				}
			}
			.block_text{
				padding: 80px 40px 130px 480px;
				background-color: $BG_GREY;
				position: relative;
				&::after{
					content: '';
					background-image: var(--bg-img);
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					width: 400px;
					height: 280px;
					position: absolute;
					top: 80px;
					left: 40px;
				}
				p{
					@include font(20, 26, 400);
					margin-bottom: 10px;
					&:last-child{
						margin-bottom: unset;
					}
					&.margin{
						margin-top: 80px;
					}
					&.bolder{
						font-weight: 700;
					}
				}
				ul{
					padding: 0 0 0 20px;
					li {
						list-style-type: disc;
						@include font(20, 26, 400);
					}
				}
			}
		}
		.filter{
			width: 325px;
			flex-shrink: 0;
			margin-right: 20px;
			.box{
				padding: 0 0 30px 0;
				background-color: $WHITE;
				border-bottom: 1px solid $LN_GREY;
				position: relative;
				p.b_head{
					@include font(20, 26, 400);
					margin-bottom: 20px;
					width: 100%;
				}
				.filter_body{
					padding: 10px 20px;
					display: flex;
					flex-wrap: wrap;
					&.collapse{
						height: 215px;
						overflow: hidden;
					}
					&.sml{
						height: 165px;
					}
					&:is(ul){
						flex-direction: column;
						flex-wrap: nowrap;
					}
					li.chk_item{
						display: flex;
						align-items: center;
						margin-bottom: 15px;
						&:last-child{
							margin-bottom: unset;
						}
						&:not([data-label="1"]){
							display: none;
						}
						label{
							cursor: pointer;
							position: relative;
							width: 20px;
							height: 20px;
							margin-right: 15px;
							&:before{
								cursor: pointer;
								content: "";
								position: absolute;
								width: 20px;
								height: 20px;
								display: block;
								left: 0;
								top: 0;
								background-color: $WHITE;
								border-radius: 2px;
								border: 1px solid $LN_GREY;
							}
						}
						input[type="checkbox"]{
							display: none;
							&:checked + label::after{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								background-image: url(../img/icon/filter/ic-chk-checked_bk.svg);
								background-repeat: no-repeat;
								background-size: cover;
								width: 20px;
								height: 20px;
							}
							&:disabled + label::before{
								pointer-events: none;
								background-color: $LN_GREY;
							}
						}
						p.key{
							@include font(16, 24, 400);
							span.count{
								color: $T_GREYBD;
								display: inline-block;
								margin-left: 10px;
							}
						}
					}
				}
				.all_trigger{
					color: var(--promo-color);
					cursor: pointer;
					position: absolute;
					background-color: $WHITE;
					@include font(16, 24, 400);
					bottom: 0;
					width: 100%;
					height: 50px;
					align-items: flex-start;
					padding: 10px 20px;
				}
				.filter_head{
					padding: 10px 20px;
					background-color: $BG_WHITE;
					a.toggle{
						width: 28px;
						height: 28px;
						background-size: 15px auto;
						position: absolute;
						top: 10px;
						right: 20px;
						transform: rotate(180deg);
						transition: transform .25s;
						&.open{
							transform: rotate(0deg);
						}
					}
				}
				&.used_data{
					padding-top: 40px;
					position: sticky;
					top: 0;
					z-index: 1;
					.filter_head{
						padding: 0 20px;
						background-color: unset;
						margin-bottom: 20px;
					}
				}
				.apply_filter,
				.clear_filter{
					position: absolute;
					top: 42px;
					right: 20px;
					@include font(16, 21, 400);
					color: $T_GREY82;
					&.apply_filter{
						top: unset;
						bottom: 10px;
						opacity: 0;
						transition: opacity .25s;
						&.shown{
							opacity: 1;
						}
					}
				}
				.filter_item{
					flex-basis: 1;
					@include font(12, 25, 400);
					padding: 0 30px 0 10px;
					background-color: $BG_GREY;
					border-radius: 18px;
					position: relative;
					margin: 0 10px 10px 0;
					i.del{
						position: absolute;
						top: 9px;
						right: 14px;
						width: 8px;
						height: 8px;
					}
				}

			}
		}
		.items_wrap{
			display: flex;
			flex-wrap: wrap;
			flex-shrink: 0;
			justify-content: space-between;
			align-content: flex-start;
			width: calc(100% - 345px);
			.item{
				margin-bottom: 20px;
			}
		}
		.pagenator{
			padding: 0 0 0 345px;
			.btns{
				display: flex;
				justify-content: center;
				margin-top: 30px;
				margin-bottom: 100px;
			}
			.btn{
				background-image: url(../img/icon/ic-arrow3.svg);
				background-size: auto 20px;
				background-position: center;
				width: 40px;
				height: 40px;
				cursor: pointer;
				transition: box-shadow .25s;
				&.next{
					transform: rotate(180deg);
				}
				&.disabled{
					opacity: .25;
					pointer-events: none;
				}
				&:hover{
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, .28);
				}
			}
			ul.pages{
				display: flex;
				li{
					@include font(16, 40, 400);
					width: 16px;
					margin: 0 10px;
					text-align: center;
					&.active{
						border-bottom: 4px solid var(--promo-color);
					}
				}
			}
		}
		&[data-cat="machinery"]{
			.items_wrap{
				.item{
					height: 360px;
					margin-right: unset;
					&:hover::after{
						content: '';
						position: absolute;
						width: 15px;
						height: 15px;
						border-radius: 50%;
						background-color: var(--promo-color);
						bottom: 15px;
						right: 35px;
					}
					.poster{
						background-size: cover;
					}
					.flags{
						top: 25px;
						left: 25px;
					}
					.rate{
						top: 25px;
						right: 25px;
					}
					.info{
						margin-bottom: unset;
						a.key{
							font-size: 19px;
						}
					}
					&:not([brand="label_1"]){
						display: none;
					}
				}
			}
		}
	}
	&.finprogs{
		.block_poster{
			// margin-bottom: 100px;
			.slide_item{
				height: 550px;
				margin-bottom: 40px;
				position: relative;
				.bg{
					height: 550px;
					width: 100%;
					position: absolute;
					overflow: hidden;
					z-index: 0;
					.part{
						height: 100%;
						width: 100%;
						&.left{
							position: absolute;
							background-color: $RED;
							top: 0;
							left: 0;
							z-index: -1;
						}
						&.right{
							position: absolute;
							width: 720px;
							height: 550px;
							top: 0;
							right: 0;
							z-index: 0;
							.background{
								position: relative;
								width: inherit;
								height: inherit;
								background-color: $FULL_BLACK;
								background-image: var(--s-img);
								background-repeat: no-repeat;
								background-size: contain;
								background-position: center;
								border-top-left-radius: 50%;
								border-bottom-left-radius: 50%;
								height: 1700px;
								width: 1600px;
								top: -600px;
								left: -300px;
							}
						}
					}
				}
				.data{
					position: relative;
					height: inherit;
					display: flex;
					justify-content: space-between;
					.part{
						position: relative;
						&.left{
							position: relative;
							padding: 100px 0 100px 165px;
							width: 720px;
							flex-shrink: 0;
							color: $WHITE;
							z-index: 0;
							&:after{
								content: '';
								position: absolute;
								width: 620px;
								height: 420px;
								left: 130px;
								top: 50%;
								transform: translateY(-50%);
								border-radius: 40px;
								background: linear-gradient(180deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .2) 100%);
								backdrop-filter: blur(25px);
								z-index: -1;
							}
							h2.item_title{
								@include font(48, 50, 400);
								margin-bottom: 20px;
							}
							p.info{
								@include font(22, 29, 400);
							}
							a.slider{
								padding: 0 20px;
								height: 50px;
								color: $FULL_BLACK;
								@include font(22, 50, 400);
								background-color: $WHITE;
								position: absolute;
								bottom: 100px;
								left: 165px;
							}
							.socials{
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: 40px;
								height: 160px;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
							}
						}
					}
				}
			}
		}
		.agrospace{
			background-color: $BG_GREY;
			padding: 150px 40px 150px 40px;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			position: relative;
			margin-bottom: 100px;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				color: $RED;
				position: absolute; // FIXIT!!!!!
				width: 400px;
				top: 150px;
				left: 40px;
			}
			.alt_news{
				flex-shrink: 0;
				width: 400px;
				padding: 40px 0;
				margin-bottom: 60px;
				.alt_item{
					p.subtitle{
						@include font(28, 40, 400);
						margin-bottom: 10px;
					}
					p.key{
						@include font(20, 26, 400);
					}
				}
			}
			.main_news_gal{
				position: relative;
				padding: 40px 0;
				width: 920px;
				margin-bottom: 60px;
				.gal_wrapper{
					height: 500px;
					display: flex;
					align-items: center;
					margin-left: -200px; // slider shift fix
				}
				.alt_item{
					flex-shrink: 0;
					width: 520px; // keep wide to avoid shifting
					height: 345px;
					background-color: $WHITE;
					background-image: var(--n-bg);
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					transition: all .25s;
					&.swiper-slide-active{
						width: 520px;
						height: 500px;
					}
				}
				.news_pagination{
					width: 520px;
					height: 2px;
					background: $T_GREYB5;
					top: unset;
					bottom: 0;
					left: 0;
					.swiper-pagination-progressbar-fill{
						background: $FULL_BLACK;
					}
				}
				.gal_controls{
					position: absolute;
					bottom: 50px;
					right: 280px;
					width: 80px;
					height: 20px;
					display: flex;
					justify-content: space-between;
					z-index: 1;
					a.btn{
						width: 30px;
						height: 20px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						&.prev{
							transform: rotate(180deg);
						}
					}
				}
			}
			.news_tail{
				position: absolute;
				width: 220px;
				bottom: 100px;
				left: calc(50% - 110px);
				display: flex;
				justify-content: center;
				a.arrow{
					position: relative;
					padding: 0 25px 0 0;
					@include font(20, 26, 400);
					color: $RED;
					&:after{
						content: '';
						position: absolute;
						width: 25px;
						height: 26px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-repeat: no-repeat;
						background-size: 80%;
						background-position: center;
						top: 0;
						right: 0;
					}
				}
			}
		}
		
		.page_controls{
			padding: 100px 40px;
			margin-bottom: 50px;
			background-color: $BG_GREY;
			h2.item-title{
				@include font(48, 50, 400);
				text-transform: uppercase;
				color: var(--point-color);
				margin-bottom: 80px;
			}
			.key{
				p {
					@include font(28, 37, 400);
				}
			}
			ul.side{
				margin-bottom: 40px;
				li{
					border-bottom: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					padding: 32px 0;
					&:first-child{
						border-top: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					a{
						display: block;
						width: fit-content;
						transition: all .25s;
						@include font(24, 36, 400);
						position: relative;
						padding: 0 100px 0 0; // 50px
						text-transform: uppercase;
						&:before{
							content: '';
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: var(--point-color);;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: 0;
							opacity: 0;
						}
						&:after{
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 30px;
							height: 30px;
							background-image: url(../img/icon/ic-arrow.svg);
							background-repeat: no-repeat;
							background-size: contain;
							transition: all .25s;
						}
						&:hover{
							width: 100%;
							padding: 0 100px 0 60px;
							color: var(--point-color);
							&:after{
								background-image: url(../img/icon/ic-arrow_green.svg);
							}
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.fin_progs{
			padding: 0 40px;
			margin-bottom: 100px;
			.partners_box{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 50px;
				.box{
					width: 400px;
					height: 150px;
					background-color: $WHITE;
					background-image: var(--p-img);
					background-repeat: no-repeat;
					background-size: 50%;
					background-position: center;
					border: 5px solid transparent;
					margin-bottom: 40px;
					transition: all .25s;
					cursor: pointer;
					&:nth-last-child(-n+3){
						margin-bottom: unset;
					}
					&:hover{
						border-top-color: $RED;
						border-right-color: $RED;
					}
				}
			}
			a.red{
				background-color: $RED;
				color: $WHITE;
				padding: 0 25px;
				@include font(20, 70, 400);
				width: fit-content;
				margin: 0 auto;
			}
		}
	}
	&.innovation{
		.farming_poster{
			padding: 0 40px;
			margin-bottom: 100px;
			.box_wrap{
				width: 100%;
				height: 700px;
				background-image: var(--p-bg);
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 40px;
				.box{
					flex-basis: calc(100% / 3);
					border-right: 1px solid $WHITE;
					border-left: 1px solid $WHITE;
				}
			}
			.farming_alt{
				p {
					@include font(28, 37, 400);
					padding: 0 0 0 480px;
				}
			}
		}
		.fs_head{
			padding: 60px 40px 0 40px;
			margin-bottom: 150px;
			.headline{
				h2{
					@include font(48, 64, 400);
					text-align: center;
					text-transform: uppercase;
					color: var(--promo-color);
					margin-bottom: 40px;
				}
				ul{
					padding: 0 0 0 500px;
					@include font(28, 37, 400);
					margin-bottom: 80px;
					li{
						list-style: disc;
					}
				}
			}
		}
		.pack_services{
			padding: 0 40px 70px 40px;
			margin-bottom: 100px;
			position: relative;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				margin-bottom: 40px;
				color: $BG_BLACK;
			}
			.service_tail{
				position: absolute;
				bottom: 0;
				display: flex;
				justify-content: center;
				width: 100%;
			}
			.service_box{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-evenly;
				.box_item{
					width: calc(50% - 140px);
					height: 570px;
					.image{
						width: 100%;
						height: 330px;
						background-color: var(--bg-color);
						background-image: var(--s-img);
						background-repeat: no-repeat;
						// background-size: 70% auto;
						background-size: cover;
						background-position: center;
					}
					p.info{
						background-color: $BG_BLACK;
						@include font(20, 26, 400);
						padding: 20px 80px 20px 20px;
						color: $WHITE;
						text-transform: uppercase;
						position: relative;
						cursor: pointer;
						&:before,
						&:after{
							content: '';
							position: absolute;
							opacity: 0;
							transition: opacity .25s;
						}
						&:before{
							width: 35px;
							height: 35px;
							top: 30px;
							right: 30px;
							background-image: url(../img/icon/ic-promo-arrow_white.svg);
							background-repeat: no-repeat;
							background-size: contain;
						}
						&:after{
							width: 15px;
							height: 15px;
							top: 20px;
							right: 90px;
							background-color: $RED;
							border-radius: 50%;
						}
						&:not(.nohover):hover{
							&:before,
							&:after{
								opacity: 1;
							}
						}
					}
					.legend {
						background-color: #ebebeb;
						@include font(16, 20, 400);
						padding: 20px;
						color: $FULL_BLACK;
						min-height: 130px;
						display: flex;
						align-items: center;
					}
				}
			}
		}
		.po{
			padding: 0 40px;
			margin-bottom: 100px;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 40px;
				color: var(--promo-color);
			}	
			.table_wrap{
				display: flex;
				justify-content: space-between;
				min-height: 570px; // YEEET!!!
			}
			.table_sw{
				flex-shrink: 0;
				width: 400px;
				.sw_item{
					display: flex;
					align-items: center;
					cursor: pointer;
					height: 100px;
					border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					&:first-child{
						border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					p.key{
						@include font(24, 26, 400);
						margin: unset;
						text-align: unset;
					}
					&.active{
						color: var(--promo-color);
						height: 140px;
						p.key{
							padding: 0 0 0 60px;
							position: relative;
							&:before{
								content: '';
								position: absolute;
								width: 40px;
								height: 40px;
								border-radius: 50%;
								background-color: var(--po-color);
								top: 50%;
								transform: translateY(-50%);
								left: 0;
							}
						}
					}
				}
			}
			.table_body{
				flex-shrink: 0;
				width: calc(100% - 480px);
				.body_item{
					border-top: 2px solid $FULL_BLACK;
					border-bottom: 2px solid $FULL_BLACK;
					display: none;
					&.active{
						display: block;
					}
					table {
						border-collapse: collapse;
						width: 100%;
					}
					tr{
						border-bottom: 2px solid $FULL_BLACK;
						width: 100%;
						&:last-child{
							border-bottom: unset;
						}
						td,
						th{
							.poster{
								width: 400px;
								height: 240px;
								background-image: var(--bg-img);
								background-repeat: no-repeat;
								background-size: cover;
								background-position: center;
							}
						}
						th{
							padding: 20px 0 20px 0;
							
						}
						td{
							padding: 20px 20px 20px 80px;
							position: relative;
							vertical-align: baseline;
							display: flex;
							align-items: center;
							&.active{
								padding: 30px 30px 30px 40px;
							}
							p.subtitle{
								@include font(28, 37, 400);
								margin-bottom: 10px;
								text-align: unset;
							}
							.key{
								@include font(18, 20, 400);
								margin-bottom: unset;
								text-align: unset;
								ul{
									li {
										list-style: disc;
										margin-bottom: 5px;
										&:last-child{
											margin-bottom: unset;
										}
									}
								}
							}
							a.more{
								color: $RED;
								@include font(20, 26, 400);
								position: absolute;
								bottom: 20px;
								left: 80px;
							}
						}
					}
				}
			}
		}
		.social_resp{
			padding: 0 40px;
			margin-bottom: 100px;
			h2.item_title{
				@include font(48, 50, 400);
				color: $RED;
				margin-bottom: 40px;
			}
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				.box{
					padding: 40px;
					height: 330px;
					&:not(.full),
					&:not(.half2){
						flex-basis: calc(100% / 3);
						&:nth-child(4){
							border-top: unset;
							border-right: unset;
						}
					}
					&.bg{
						background-size: cover;
						background-position: center;
					}
					&.half2{
						flex-basis: calc( (100%/3) * 2 );
						margin-left: auto;
						border: unset;
						&:after{
							content: none;
						}
						p.item_title{
							@include font(28, 32, 400);
							margin-bottom: 10px;
						}
						.key{
							p {
								text-align: unset;
							}
						}
						position: relative;
						.text_wrap{
							position: unset;
							.item_title{
								transition: color .25s;
							}
							&::after{
								content: '';
								position: absolute;
								width: 60px;
								height: 40px;
								background-image: url(../img/icon/ic-promo-arrow_red.svg);
								background-position: center;
								background-size: contain;
								background-repeat: no-repeat;
								bottom: 20px;
								left: unset;
								right: 20px;
							}
						}
						&.noarrow{
							.text_wrap{
								&::after{
									content: none;
								}
							}
							&:hover{
								.text_wrap{
									.item_title{
										color: rgba($color: $RED, $alpha: .8);
									}
								}
							}
						}
					}
				}
			}
		}
		&.farm_sight{
			.table_sw{
				.sw_item{
					border: unset;
					&:first-child{
						border: unset;
					}
				}
			}
			.table_body{
				.body_item{
					tr{
						td{
							.key{
								ul{
									li{
										list-style: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.blog_page{
		.library{
			padding: 100px 40px 30px 40px;
			margin-bottom: 100px;
			background-color: $BG_GREY;
			h2.item_title{
				@include font(48, 50, 400);
				text-transform: uppercase;
				color: $RED;
				margin-bottom: 80px;
			}
			.main_video_gal{
				position: relative;
				margin-bottom: 50px;
				padding: 0 0 40px 0;
				.gal_wrapper{
					height: 500px;
					display: flex;
					margin-left: -255px; // slider shift fix
				}
				.alt_item{
					flex-shrink: 0;
					width: 400px; // keep wide to avoid shifting
					height: 280px;
					transition: all .25s;
					&.swiper-slide-active{
						width: 890px;
						height: 500px;
					}
					iframe{
						width: 100%;
						height: 100%;
					}
				}
				.gal_pagination{
					width: 520px;
					height: 2px;
					background: $T_GREYB5;
					top: unset;
					bottom: 0;
					left: 15px;
					.swiper-pagination-progressbar-fill{
						background: $FULL_BLACK;
					}
				}
				.gal_controls{
					position: absolute;
					bottom: 50px;
					right: 280px;
					width: 80px;
					height: 20px;
					display: flex;
					justify-content: space-between;
					z-index: 1;
					a.btn{
						width: 30px;
						height: 20px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						&.prev{
							transform: rotate(180deg);
						}
					}
				}
			}
			.news_tail{
				position: absolute;
				width: 220px;
				bottom: 0;
				left: calc(50% - 110px);
				display: flex;
				justify-content: center;
				a.arrow{
					position: relative;
					padding: 0 25px 0 0;
					@include font(20, 26, 400);
					color: $RED;
					&:after{
						content: '';
						position: absolute;
						width: 25px;
						height: 26px;
						background-image: url(../img/icon/ic-arrow_red.svg);
						background-repeat: no-repeat;
						background-size: 80%;
						background-position: center;
						top: 0;
						right: 0;
					}
				}
			}
		}
		.blog{
			padding: 0 40px;
			margin-bottom: 100px;
			.headline{
				display: flex;
				justify-content: space-between;
				margin-bottom: 40px;
				h2{
					@include font(48, 50, 400);
					text-transform: uppercase;
				}
				a.btn{
					@include font(22, 50, 400);
					color: $WHITE;
					background-color: $RED;
					padding: 0 15px;
				}
			}
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.box{
					// background-image: var(--bg-img);
					// background-repeat: no-repeat;
					// background-size: cover;
					// background-position: center;
					width: 400px;
					height: 330px;
					position: relative;
					margin-bottom: 40px;
					&:nth-last-child(-n+3){
						margin-bottom: unset;
					}
					.poster{
						width: 100%;
						background-image: var(--bg-img);
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						height: 255px;
					}
					.alt_box{
						height: 90px;
						width: 100%;
						background-color: $T_BLACK;
						color: $WHITE;
						position: absolute;
						bottom: 0;
						display: flex;
						align-items: center;
						p{
							@include font(16, 20, 400);
							text-transform: uppercase;
							padding: 0 95px 0 20px;
							position: relative;
							&::after{
								content: '';
								position: absolute;
								width: 35px;
								height: 35px;
								background-image: url(../img/icon/ic-promo-arrow_white.svg);
								background-position: center;
								background-size: contain;
								background-repeat: no-repeat;
								top: 50%;
								transform: translateY(-50%);
								right: 20px;
								opacity: 0;
								transition: opacity .25s;
							}
						}
						&:hover{
							p::after{
								opacity: 1;
							}
						}
					}
				}
			}
			.news_tail{
				padding: 50px 0 0 0;
				display: flex;
				justify-content: center;
			}
		}
	}
	&.pack_service{
		.farming_poster{
			padding: 0 40px;
			margin-bottom: 100px;
			.box_wrap{
				width: 100%;
				height: 590px;
				background-image: var(--p-bg);
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				margin-bottom: 40px;
				.box{
					flex-basis: calc(100% / 3);
					border-right: 1px solid $WHITE;
					border-left: 1px solid $WHITE;
					&:nth-child(odd){
						border: 1px solid transparent;
					}
				}
			}
			.farming_alt{
				p {
					@include font(28, 37, 400);
					padding: 0 0 0 480px;
				}
			}
		}
		.pack_services{
			padding: 0 40px 70px 40px;
			margin-bottom: 100px;
			position: relative;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				margin-bottom: 40px;
				color: $BG_BLACK;
			}
			.service_tail{
				position: absolute;
				bottom: 0;
				display: flex;
				justify-content: center;
				width: 100%;
			}
			.service_box{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-evenly;
				.box_item{
					width: 400px;
					margin-bottom: 20px;
					&:nth-last-child(-n+3){
						margin-bottom: unset;
					}
					// &:nth-child(n+4){
					// 	display: none;
					// }
					.image{
						width: 100%;
						height: 260px;
						background-color: var(--bg-color);
						background-image: var(--s-img);
						background-repeat: no-repeat;
						// background-size: 70% auto;
						background-size: cover;
						background-position: center;
					}
					.info{
						background-color: $BG_BLACK;
						height: 100px;
						display: flex;
						align-items: center;
						p{
							@include font(18, 20, 400);
							span{
								@include font(12, 14, 400);
								&.hider{
									position: relative;
									padding: 0 15px 0 0;
									cursor: pointer;
									&:after{
										content: '?';
										position: absolute;
										width: 12px;
										height: 12px;
										top: -1px;
										right: 0;
										@include font(10, 16, 400);
										text-align: center;
										background-color: $WHITE;
										color: black;
										border-radius: 50%;
									}
									&:before{
										content: attr(content);
										position: absolute;
										top: -145px;
										left: -84px;
										height: 100px;
										width: 400px;
										@include font(16, 20, 400);
										background-color: black;
										padding: 10px 20px;
										opacity: 0;
										transition: opacity .15s;
									}
									&:hover{
										&:before{
											opacity: 1;
										}
									}
								}
							}
							&.smaller{
								@include font(16, 18, 400);
							}
						}
						padding: 20px 50px 20px 20px;
						color: $WHITE;
						text-transform: uppercase;
						position: relative;
						cursor: pointer;
						&:before,
						&:after{
							content: '';
							position: absolute;
							opacity: 0;
							transition: opacity .25s;
						}
						&:before{
							width: 20px;
							height: 20px;
							top: 50%;
							transform: translateY(-50%);
							right: 15px;
							background-image: url(../img/icon/ic-promo-arrow_white.svg);
							background-repeat: no-repeat;
							background-size: contain;
						}
						&:hover{
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	&.splicing{
		// .fertilizers{
		// 	padding: 60px 40px 0 40px;
		// 	margin-bottom: 150px;
		// 	.headline{
		// 		h2{
		// 			@include font(48, 64, 400);
		// 			text-align: center;
		// 			text-transform: uppercase;
		// 			color: $RED;
		// 			margin-bottom: 40px;
		// 		}
		// 		// p{
		// 		// 	@include font(28, 37, 400);
		// 		// 	max-width: 890px;
		// 		// 	margin: 0 auto;
		// 		// 	margin-bottom: 80px;
		// 		// }
		// 	}
		// 	.farming_poster{
		// 		.box_wrap{
		// 			width: 100%;
		// 			height: 450px;
		// 			background-image: var(--p-bg);
		// 			background-position: center;
		// 			background-size: cover;
		// 			background-repeat: no-repeat;
		// 			margin-bottom: 40px;
		// 			.box{
		// 				flex-basis: calc(100% / 4);
		// 				border-right: 1px solid $WHITE;
		// 				border-left: 1px solid $WHITE;
		// 				padding-top: 140px;
		// 				&:nth-child(odd){
		// 					border: 1px solid transparent;
		// 				}
		// 				p.item_title{
		// 					text-align: center;
		// 					@include font(32, 35, 400);
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		.promo_slider{
			@include promo_slider{
				margin-bottom: 0;
				.slide_item{
					.bg .part.right .background{
						background-size: 80%;
						background-position: 0 60%;
					}
				}
			}
		}
		.agrocoach{
			@include agrocoach_block{
				margin-bottom: 100px;
				background-color: $BG_GREY;
				padding: 50px 40px;
				margin-bottom: 50px;
				.side{
					&.left{
						color: $BLACK;
					}
					&.right{
						iframe{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
		.values{
			@include values_block{
				.box_wrap{
					justify-content: space-around;
					.box{
						min-height: 400px;
					}
				}
			}
		}
		&.agrohim{
			.values .box_wrap .box{
				min-height: 270px;
			}
			.chess_wrapper .box .bg_image{
				background-position: 0 72%;
			}
		}
		.po{
			@include po_block{
				.table_wrap{
					min-height: unset;
					.table_body{
						.body_item{
							th{
								.poster{
									height: 300px;
									background-size: cover;
								}
							}
						}
					}
				}
			}
		}
		&>.item_title{
			@include item_title{
				margin-bottom: 0px;
				@include mobile{
					margin-bottom: 0px;
				}
			}
		}
		.bio{
			@include bio_block{
				.box{
					gap: 30px;
					.box_item .alt_box p::after{
						background: unset;
					}
					.box_item{
						background-size: contain;
						height: 400px;
						.alt_box{
							display: flex;
							align-items: center;
							justify-content: center;
							p {
								padding: 0 20px;
								@include font(20, 22, 400);
								text-align: center;
							}
						}
					}
				}
			}
		}
		// .pivot_watch{
			// @include po_block{
			// 	.table_wrap{
			// 		min-height: unset;
			// 	}
			// }
		// }
		.chess_wrapper{
			@include chess_wrapper{
				.box{
					flex-direction: row-reverse;
					align-items: flex-start;
					margin-bottom: 80px;
					.text_wrap{
						flex: 0 1 450px;
						span.red{
							color: $T_RED;
						}
						ul {
							li {
								@include font(22, 26, 400);
								margin-bottom: 10px;
								&:last-child{
									margin-bottom: unset;
								}
							}
						}
					}
				}
			}
		}
		.advantages{
			padding: 0 20px;
			margin-bottom: 100px;
			.subtitle_wrap{
				margin-bottom: 60px;
				h2.item_title{
					@include item_title;
				}
			}
			.box_wrap{
				padding: 0 0 0 120px;
				.box{
					position: relative;
					padding: 20px 0 20px 80px;
					transition: all .25s;
					cursor: pointer;
					border-bottom: 1px solid $T_GREY82;
					&:first-child{
						border-top: 1px solid $T_GREY82;
					}
					p.key{
						@include font(20, 26, 400);
					}
					&:before{
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: 20px;
						width: 40px;
						height: 40px;
						background-color: $RED;
						border-radius: 50%;
						transition: opacity .25s;
						opacity: 0;
					}
					&:hover{
						padding-left: 120px;
						&::before{
							opacity: 1;
						}
					}
				}
				&.box4{
					.box{
						width: calc((100% / 4) - 40px);
						min-height: 350px;
					}
				}
			}
		}
		.agh_services{
			padding: 0 40px 70px 40px;
			margin-bottom: 100px;
			position: relative;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 40px;
				color: $BG_BLACK;
			}
			.service_tail{
				position: absolute;
				bottom: 0;
				display: flex;
				justify-content: center;
				width: 100%;
			}
			.service_box{
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				.box_item{
					width: 700px;
					height: 420px;
					flex-shrink: 0;
					.image{
						width: 100%;
						height: 330px;
						background-color: var(--bg-color);
						background-image: var(--s-img);
						background-repeat: no-repeat;
						// background-size: 70% auto;
						background-size: cover;
						background-position: center;
					}
					.info{
						background-color: $BG_BLACK;
						@include font(20, 26, 400);
						padding: 20px 80px 20px 20px;
						color: $WHITE;
						text-transform: uppercase;
						position: relative;
						cursor: pointer;
						&:before,
						&:after{
							content: '';
							position: absolute;
							opacity: 0;
							transition: opacity .25s;
						}
						&:before{
							width: 20px;
							height: 20px;
							top: 50%;
							transform: translateY(-50%);
							right: 20px;
							background-image: url(../img/icon/ic-promo-arrow_white.svg);
							background-repeat: no-repeat;
							background-size: contain;
						}
						&:hover{
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		// .innovations{
		// 	.box_wrap{
		// 		.box{
		// 			.key{
		// 				p{
		// 					text-align: left;
		// 					@include font(18, 24, 400);
		// 				}
		// 			}
		// 		}
		// 	}
		// }
		.question_block{
			padding: 0 0 50px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			h2.item-title{
				@include font(48, 50, 400);
				position: relative;
				width: fit-content;
				text-transform: uppercase;
				margin-bottom: 20px;
				&:after{
					content: '';
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background-color: $RED;
					position: absolute;
					top: -25px;
					right: -70px;
				}
			}
			p.key{
				@include font(20, 50, 400);
				margin-bottom: 50px;
			}
			a.send{
				padding: 0 70px;
				background-color: $RED;
				color: $WHITE;
				@include font(20, 65, 400);
				position: relative;
			}
		}
	}

	&.personal_page{
		.block_poster{
			margin-bottom: 150px;
			.key{
				position: relative;
				padding-bottom: 0;
				&:before{
					content: '';
					background-image: var(--logo);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					width: 350px;
					height: 100px;
					position: absolute;
					left: 50px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		.box{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 40px;
			flex-wrap: wrap;
			.text_wrap{
				max-width: calc(40% - 40px);
				h2{
					@include font(40, 46, 400);
					margin-bottom: 60px;
				}
				p{
					@include font(24, 28, 400);
				}
				ul{
					padding: 0 0 0 20px;
					margin-bottom: 30px;
					li{
						@include font(24, 28, 400);
						list-style-type: disc;
					}
				}
				a.marked{
					color: var(--promo-color);
					@include font(22, 24, 400);
					width: fit-content;
				}
			}
			.bg_image{
				background-image: var(--bg-img);
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				width: 60%;
				height: 500px;
			}
			.type_title{
				flex-basis: 100%;
				margin-top: 50px;
				h2{
					@include font(40, 46, 400);
					text-align: center;
					color: var(--promo-color);
				}
			}
			&.center{
				max-width: 1100px;
				margin: 0 auto;
				margin-bottom: 50px;
				flex-direction: column;
				.text_wrap{
					max-width: unset;
				}
				.table_wrap{
					flex: unset;
				}
			}
		}
		.chess_wrapper{
			padding: 0 40px;
			&:not(.unchess){
				.box:nth-child(even){
					flex-direction: row-reverse;
				}
			}
		}
		.question_block{
			padding: 100px 0 150px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			h2.item-title{
				@include font(48, 50, 400);
				position: relative;
				width: fit-content;
				text-transform: uppercase;
				margin-bottom: 20px;
				&:after{
					content: '';
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background-color: var(--promo-color);
					position: absolute;
					top: -25px;
					right: -70px;
				}
			}
			p.key{
				@include font(20, 50, 400);
				margin-bottom: 50px;
			}
			a.send{
				padding: 0 70px;
				background-color: var(--promo-color);
				color: $WHITE;
				@include font(20, 65, 400);
				position: relative;
			}
		}
		.link_block{
			padding: 40px 0;
			margin-bottom: 60px;
			h2.subtitle{
				@include font(48, 64, 400);
				text-transform: uppercase;
				margin-bottom: 40px;
			}
			.service_box{
				display: flex;
				justify-content: space-between;
				.box_item{
					width: 700px;
					height: 420px;
					.image{
						width: 100%;
						height: 330px;
						background-color: var(--bg-color);
						background-image: var(--s-img);
						background-repeat: no-repeat;
						// background-size: 70% auto;
						background-size: cover;
						background-position: center;
					}
					p.info{
						background-color: $BG_BLACK;
						@include font(20, 26, 400);
						padding: 20px 80px 20px 20px;
						color: $WHITE;
						text-transform: uppercase;
						position: relative;
						cursor: pointer;
						&:before,
						&:after{
							content: '';
							position: absolute;
							opacity: 0;
							transition: opacity .25s;
						}
						&:before{
							width: 20px;
							height: 20px;
							top: 50%;
							transform: translateY(-50%);
							right: 20px;
							background-image: url(../img/icon/ic-promo-arrow_white.svg);
							background-repeat: no-repeat;
							background-size: contain;
						}
						&:hover{
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.table_box{
			display: flex;
			justify-content: center;
			margin-bottom: 100px;
			.table_init{
				width: fit-content;
				colgroup{
					display: none;
				}
				table, th, td {
					border: 1px solid $FULL_BLACK;
					border-collapse: collapse;
				}
				tbody{
					width: 100%;
					margin: 0 auto;
					tr{
						&:first-child{
							background-color: $LN_GREY;
							td{
								font-weight: 600;
							}
						}
						td{
							padding: 10px 15px;
							min-height: 40px;
							text-align: center;
						}
					}
				}
			}
		}
		&.noimage{
			.box{
				margin: 0 auto;
				max-width: 1100px;
				margin-bottom: 50px;
				.text_wrap{
					max-width: unset;
					width: 100%;
				}
			}
			.question_block{
				padding: 100px 0 150px 0;
			}
		}
		&.drones{
			.block_poster{
				margin-bottom: 40px;
				.key{
					text-align: center;
					padding: 0 40px 10px 40px;
				}
			}
			a.margin{
				margin: 0 auto;
				margin-top: 80px;
				width: fit-content;
				display: block;
				padding: 0 15px;
				@include font(18, 50, 400);
				background-color: var(--promo-color);
				color: $WHITE;
			}
			.fs_head{
				padding: 60px 40px 0 40px;
				margin-bottom: 100px;
				.headline{
					h2{
						@include font(48, 64, 400);
						text-align: center;
						text-transform: uppercase;
						color: var(--promo-color);
						margin-bottom: 40px;
					}
					ul{
						padding: 0 0 0 500px;
						@include font(28, 37, 400);
						margin-bottom: 80px;
						li{
							list-style: disc;
						}
					}
					p {
						@include font(28, 37, 400);
						margin-bottom: 40px;
					}
				}
				a.btn.more{
					margin: 0 auto;
					margin-top: 20px;
					width: fit-content;
					display: block;
					padding: 0 15px;
					@include font(18, 50, 400);
					background-color: var(--promo-color);
					color: $WHITE;
				}
			}
			.page_controls{
				padding: 100px 40px 50px 40px;
				background-color: $BG_GREY;
				h2.item-title{
					@include font(48, 50, 400);
					text-transform: uppercase;
					color: var(--point-color);
					margin-bottom: 20px;
				}
				.key{
					margin-bottom: 40px;
					p {
						@include font(28, 37, 400);
					}
				}
				ul.side{
					margin-bottom: 40px;
					li{
						border-bottom: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
						padding: 32px 0;
						&:first-child{
							border-top: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
						}
						a{
							display: block;
							width: fit-content;
							transition: all .25s;
							@include font(24, 36, 400);
							position: relative;
							padding: 0 100px 0 0; // 50px
							text-transform: uppercase;
							&:before{
								content: '';
								width: 40px;
								height: 40px;
								border-radius: 50%;
								background-color: var(--point-color);;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: 0;
								opacity: 0;
							}
							&:after{
								content: '';
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								right: 0;
								width: 30px;
								height: 30px;
								background-image: url(../img/icon/ic-arrow.svg);
								background-repeat: no-repeat;
								background-size: contain;
								transition: all .25s;
							}
							&:hover{
								width: 100%;
								padding: 0 100px 0 60px;
								color: var(--point-color);
								&:after{
									background-image: url(../img/icon/ic-arrow_green.svg);
								}
								&:before{
									opacity: 1;
								}
							}
						}
					}
				}
			}
			.question_block{
				padding: 0 0 50px 0;
				.key{
					p{
						@include font(20, 50, 400);
						margin-bottom: 15px;
						text-align: center;
						&:last-child{
							margin-bottom: 30px;
						}
					}
				}
			}
			.link_block{
				padding: 40px 40px;
				margin-bottom: 60px;
				h2.subtitle{
					@include font(48, 64, 400);
					text-transform: uppercase;
					margin-bottom: 40px;
					text-align: center;
				}
				.service_box{
					display: flex;
					justify-content: space-between;
					.box_item{
						width: 700px;
						height: 420px;
						.image{
							width: 100%;
							height: 330px;
							background-color: var(--bg-color);
							background-image: var(--s-img);
							background-repeat: no-repeat;
							// background-size: 70% auto;
							background-size: cover;
							background-position: center;
						}
						p.info{
							background-color: $BG_BLACK;
							@include font(20, 26, 400);
							padding: 20px 80px 20px 20px;
							color: $WHITE;
							text-transform: uppercase;
							position: relative;
							cursor: pointer;
							&:before,
							&:after{
								content: '';
								position: absolute;
								opacity: 0;
								transition: opacity .25s;
							}
							&:before{
								width: 20px;
								height: 20px;
								top: 50%;
								transform: translateY(-50%);
								right: 20px;
								background-image: url(../img/icon/ic-promo-arrow_white.svg);
								background-repeat: no-repeat;
								background-size: contain;
							}
							&:hover{
								&:before{
									opacity: 1;
								}
							}
						}
					}
				}
			}
			.box{
				align-items: flex-start;
				.text_wrap{
					flex: 0 1 450px;
					&.full{
						flex: 0 1 100%;
						max-width: unset;
						h2{
							text-align: center;
						}

					}
				}
				.table_wrap{
					flex: 0 1 calc(100% - 450px);
					table{
						width: 100%;
						th,td{
							width: 50%;
							padding: 20px;
						}
						&.group{
							tr{
								th{
									vertical-align: top;
									text-align: left;
									// font-weight: 400;
								}
								td{
									display: table-row;
									margin-bottom: 10px;
									&.bolder{
										font-weight: 600;
									}
								}
							}
						}
					}
				}
				&.smlr{
					max-width: 1100px;
					margin: 0 auto;
					margin-bottom: 50px;
					flex-direction: column;
					.text_wrap{
						flex: unset;
					}
					.table_wrap{
						flex: unset;
					}
				}
			}
		}
	}
	&.subscription,
	&.op-center,
	&.jd-link{
		.block_poster{
			.key{
				padding: 40px 40px 100px 40px;
				text-align: center;
			}
		}
		&.jd-link{
			.block_poster{
				.key{
					padding: 40px 40px 40px 500px;
					margin-bottom: 60px;
					position: relative;
					text-align: unset;
					&:before{
						content: attr(data-content);
						position: absolute;
						@include font(26, 28, 600);
						width: 350px;
						left: 75px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		.po{
			padding: 0 40px;
			margin-bottom: 100px;
			h2.item_title{
				@include font(48, 64, 400);
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 40px;
				color: var(--promo-color);
			}
			.key{
				max-width: 880px;
				margin: 0 auto;
				margin-bottom: 80px;
				p {
					font-family: 'FavoritPro';
					@include font(28, 37, 400);
					text-align: center;
					margin-bottom: 10px;
					&:last-child{
						margin-bottom: unset;
					}
				}
			}
			.table_wrap{
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				min-height: 570px; // YEEET!!!
				position: relative;
			}
			.table_sw{
				flex-shrink: 0;
				width: 400px;
				position: sticky;
				top: 0;
				.sw_item{
					display: flex;
					align-items: center;
					cursor: pointer;
					height: 100px;
					border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					&:first-child{
						border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					p.key{
						@include font(24, 26, 400);
						margin: unset;
						text-align: unset;
					}
					&.active{
						color: var(--promo-color);
						height: 140px;
						p.key{
							padding: 0 0 0 60px;
							position: relative;
							&:before{
								content: '';
								position: absolute;
								width: 40px;
								height: 40px;
								border-radius: 50%;
								background-color: var(--promo-color);
								top: 50%;
								transform: translateY(-50%);
								left: 0;
							}
						}
					}
				}
			}
			.table_body{
				flex-shrink: 0;
				width: calc(100% - 480px);
				.body_item{
					border-top: 2px solid $FULL_BLACK;
					border-bottom: 2px solid $FULL_BLACK;
					display: none;
					&.active{
						display: block;
					}
					table {
						border-collapse: collapse;
					}
					tr{
						border-bottom: 2px solid $FULL_BLACK;
						&.active{
							// height: 140px;
						}
						&:last-child{
							border-bottom: unset;
						}
						td,
						th{
							.poster{
								width: 400px;
								height: 240px;
								background-image: var(--bg-img);
								background-repeat: no-repeat;
								background-size: cover;
								background-position: center;
							}
						}
						th{
							padding: 20px 0 20px 0;
							
						}
						td{
							padding: 0 20px 0 80px;
							position: relative;
							vertical-align: baseline;
							display: flex;
							align-items: center;
							height: 97px;
							&.active{
								height: 140px;
								padding: 0 20px 0 20px;
							}
							p.subtitle{
								@include font(28, 37, 400);
								margin-bottom: 10px;
								text-align: unset;
							}
							.key{
								max-width: unset;
								margin: unset;
								margin-bottom: unset;
								p{
									@include font(18, 20, 400);
									margin-bottom: 10px;
									&:last-child{
										margin-bottom: unset;
									}
									text-align: unset;
								}
							}
							a.more{
								color: $RED;
								@include font(20, 26, 400);
								position: absolute;
								bottom: 20px;
								left: 80px;
							}
						}
					}
				}
			}
		}
		&.subscription{
			.po{
				.table_sw{
					.sw_item:first-child,
					.sw_item{
						border: unset;
					}
				}
				.table_body{
					.body_item{
						tr{
							td{
								padding-top: 20px;
								padding-bottom: 20px;
								height: unset;
							}
						}
					}
				}
			}
			.chess_wrapper{
				padding: 0 40px;
				.box{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 40px;
					flex-wrap: wrap;
					&.rev{
						flex-direction: row-reverse;
					}
					.text_wrap{
						max-width: calc(40% - 40px);
						h2{
							@include font(40, 46, 400);
							margin-bottom: 60px;
						}
						p{
							@include font(24, 28, 400);
						}
						ul{
							padding: 0 0 0 20px;
							li{
								@include font(24, 28, 400);
								list-style-type: disc;
							}
						}
					}
					.bg_image{
						background-image: var(--bg-img);
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
						width: 60%;
						height: 500px;
					}
					.type_title{
						flex-basis: 100%;
						margin-top: 50px;
						h2{
							@include font(40, 46, 400);
							text-align: center;
							color: var(--promo-color);
						}
					}
				}
			}
		}
		.question_block{
			padding: 0 0 100px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			h2.item-title{
				@include font(48, 50, 400);
				position: relative;
				width: fit-content;
				text-transform: uppercase;
				margin-bottom: 20px;
				&:after{
					content: '';
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background-color: var(--promo-color);
					position: absolute;
					top: -25px;
					right: -70px;
				}
			}
			p.key{
				@include font(20, 50, 400);
				margin-bottom: 50px;
			}
			a.send{
				padding: 0 70px;
				background-color: var(--promo-color);
				color: $WHITE;
				@include font(20, 65, 400);
				position: relative;
			}
		}
		.page_controls{
			padding: 100px 40px;
			background-color: $BG_GREY;
			h2.item-title{
				@include font(48, 50, 400);
				text-transform: uppercase;
				color: var(--point-color);
				margin-bottom: 80px;
			}
			.key{
				p {
					text-align: center;
					@include font(28, 37, 400);
				}
			}
			ul.side{
				margin-bottom: 40px;
				li{
					border-bottom: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					padding: 32px 0;
					&:first-child{
						border-top: 1px solid rgba($color: $FULL_BLACK, $alpha: .5);
					}
					a{
						display: block;
						width: fit-content;
						transition: all .25s;
						@include font(24, 36, 400);
						position: relative;
						padding: 0 100px 0 0; // 50px
						text-transform: uppercase;
						&:before{
							content: '';
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: var(--point-color);;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: 0;
							opacity: 0;
						}
						&:after{
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 30px;
							height: 30px;
							background-image: url(../img/icon/ic-arrow.svg);
							background-repeat: no-repeat;
							background-size: contain;
							transition: all .25s;
						}
						&:hover{
							width: 100%;
							padding: 0 100px 0 60px;
							color: var(--point-color);
							&:after{
								background-image: url(../img/icon/ic-arrow_green.svg);
							}
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
		.values{
			padding: 0 20px;
			margin-bottom: 150px;
			.subtitle_wrap{
				display: flex;
				justify-content: space-between;
				margin-bottom: 60px;
				h2.item_title{
					@include font(48, 50, 400);
					text-transform: uppercase;
				}
				p.key{
					@include font(20, 26, 400);
					font-style: italic;
				}
			}
			.box_wrap{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				margin-bottom: 40px;
				.box{
					position: relative;
					padding: 80px 40px 0 40px;
					min-height: 200px;
					width: calc(25% - 20px);
					background-color: $BG_GREY;
					margin-bottom: 40px;
					&:nth-last-child(-n+4){
						margin-bottom: unset;
					}
					p.key{
						@include font(20, 26, 400);
						text-align: center;
					}
					&:before{
						content: '';
						position: absolute;
						top: 15px;
						right: 15px;
						width: 50px;
						height: 50px;
						border-radius: 50%;
						background-color: var(--promo-color);
					}
					&:hover::before{
						transform: rotate(360deg);
					}
				}
			}
		}
	}
	&.parts{
		padding: 0 40px 0 40px;
		.catalog_head{
			margin-bottom: 40px;
			.block_iamge{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 40px;
				.poster{
					width: 760px;
					height: 420px;
					background-image: var(--bg-img);
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				.alt{
					width: calc(100% - 840px);
					p{
						@include font(20, 26, 400);
						margin-bottom: 5px;
						&:last-child{
							margin-bottom: unset;
						}
						&.bolder{
							font-weight: 700;
						}
					}
					a.margin{
						margin-top: 80px;
						width: fit-content;
						display: block;
						padding: 0 15px;
						@include font(18, 50, 400);
						background-color: var(--promo-color);
						color: $WHITE;
					}
					
				}
			}
			.block_text{
				padding: 80px 40px 130px 480px;
				background-color: $BG_GREY;
				position: relative;
				&::after{
					content: '';
					background-image: var(--bg-img);
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					width: 400px;
					height: 280px;
					position: absolute;
					top: 80px;
					left: 40px;
				}
				p{
					@include font(20, 26, 400);
					margin-bottom: 10px;
					&:last-child{
						margin-bottom: unset;
					}
					&.margin{
						margin-top: 80px;
					}
					&.bolder{
						font-weight: 700;
					}
				}
				ul{
					padding: 0 0 0 20px;
					li {
						list-style-type: disc;
						@include font(20, 26, 400);
					}
				}
			}
		}
		.catalog_body{
			.fs_head{
				padding: 60px 40px 0 40px;
				margin-bottom: 100px;
				.headline{
					h2{
						@include font(48, 64, 400);
						text-align: center;
						text-transform: uppercase;
						color: var(--promo-color);
						margin-bottom: 40px;
					}
					&.center{
						h2{
							text-align: center;
						}
					}
					ul{
						padding: 0 0 0 500px;
						@include font(28, 37, 400);
						margin-bottom: 80px;
						li{
							list-style: disc;
						}
					}
					p {
						@include font(28, 37, 400);
						margin-bottom: 40px;
					}
				}
				a.btn.more{
					margin: 0 auto;
					margin-top: 20px;
					width: fit-content;
					display: block;
					padding: 0 15px;
					@include font(18, 50, 400);
					background-color: var(--promo-color);
					color: $WHITE;
				}
			}
			.partners{
				padding: 40px;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 50px;
				justify-content: center;
				.p_item{
					width: 270px;
					height: 100px;
					background-image: var(--pt-bg);
					background-position: center;
					background-repeat: no-repeat;
					background-size: 80% auto;
					flex: 0 1 25%;
					position: relative;
					border: 1px solid transparent;
					transition: all .25s;
					border-radius: 5px;
					// &:nth-child(5),
					// &:nth-child(6),
					// &:nth-child(7),
					// &:nth-child(8){
					// 	flex: 0 1 30%;
					// }

					cursor: pointer;
					&.bs_a_60{
						background-size: auto 60%;
					}
					&.bs_50_a{
						background-size: 50% auto;
					}
					&.wider{
						background-size: 60% 30%;
					}
					&::before,
					&::after{
						content: '';
						background-color: $T_GREYB5;
						width: 1px;
						height: 75px;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						transition: all .05s;
					}
					&:after{
						right: -2px;
					}
					&:before{
						left: -1px;
					}
					&:hover{
						transform: scale(.99);
						box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2) inset;
						&::after,
						&::before{
							opacity: 0;
						}
					}
				}
			}
			.key{
				margin-bottom: 140px;
				p {
					@include font(26, 32, 400);
					text-align: center;
					a {
						color: $RED;
						opacity: .5;
						transition: opacity .25s;
						&:hover{
							opacity: 1;
						}
					}
				}
			}

		}
		&.service{
			.catalog_head{
				.block_iamge{
					.poster{
						background-position: left center;
					}
				}
			}
			.catalog_body{
				.contact_data{
					display: flex;
					align-items: center;
					flex-direction: column;
					margin-bottom: 100px;
					.part_data{
						width: 100%;
						max-width: 1000px;
						border-top: 2px solid $FULL_BLACK;
						.part_head{
							padding: 20px 0 10px 0;
							@include font(32, 42, 400);
							text-transform: uppercase;
						}
						.part_body{
							padding: 0 0 80px 0;
							a{
								display: block;
								@include font(20, 26, 400);
								margin-bottom: 15px;
								color: $RED;
								padding: 0 0 0 35px;
							}
							p{
								@include font(20, 26, 400);
								margin-bottom: 15px;
								&.ico.point{
									position: relative;
									padding: 0 0 0 35px;
									&:before{
										content: '';
										width: 15px;
										height: 20px;
										position: absolute;
										top: 0;
										left: 10px;
										background-image: url(../img/icon/ic-map-mark.svg);
										background-repeat: no-repeat;
										background-size: contain;
										background-repeat: no-repeat;
									}
								}
								&:last-child{
									margin-bottom: unset;
								}
								a{
									display: inline;
									padding: unset;
								}
							}
						}
					}
				}
			}
		}
	}
}
@import "modal";
@include mobile {
	@import "adp";
}