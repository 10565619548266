@mixin promo_slider {
  height: 550px;
  width: 100%;
  margin-bottom: 100px;
  .slide_item{
    height: 100%;
    .bg{
      height: 550px;
      width: 100%;
      position: absolute;
      overflow: hidden;
      z-index: 0;
      .part{
        height: 100%;
        width: 100%;
        &.left{
          position: absolute;
          background-color: $RED;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &.right{
          position: absolute;
          width: 720px;
          height: 550px;
          top: 0;
          right: 0;
          z-index: 0;
          .background{
            position: relative;
            width: inherit;
            height: inherit;
            background-color: $FULL_BLACK;
            background-image: var(--s-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            height: 1700px;
            width: 1600px;
            top: -600px;
            left: -300px;
          }
        }
      }
    }
    .data{
      position: relative;
      height: inherit;
      display: flex;
      justify-content: space-between;
      .part{
        position: relative;
        &.left{
          position: relative;
          padding: 110px 0 100px 165px;
          width: 720px;
          flex-shrink: 0;
          color: $WHITE;
          z-index: 0;
          &:after{
            content: '';
            position: absolute;
            width: 620px;
            height: 390px;
            left: 130px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 40px;
            // background: linear-gradient(180deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .6) 100%);
            background: linear-gradient(180deg, rgba(92, 90, 90, 0.6) 0%, rgba(92, 90, 90, 0.6) 100%);
            backdrop-filter: blur(25px);
            z-index: -1;
          }
          h2.item_title{
            @include font(48, 50, 400);
            margin-bottom: 20px;
          }
          p.info{
            @include font(22, 29, 400);
          }
          a.slider{
            padding: 0 20px;
            height: 50px;
            color: $FULL_BLACK;
            @include font(22, 50, 400);
            background-color: $WHITE;
            position: absolute;
            bottom: 110px;
            left: 165px;
          }
          .socials{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 40px;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }
    &.t2{
      .bg{
        .part.right{
          right: unset;
          left: 0;
          .background::before{
            left: unset;
            right: 0;
            background: linear-gradient(to right, transparent 0%, black 100%);
          }
        }
      }
      .data{
        flex-direction: row-reverse;
        justify-content: flex-start;
        .part{
          &.left{
            padding: 100px 165px 100px 0;
            a.slider{
              left: 0;
            }
            .socials{
              left: unset;
              right: 40px;
            }
          }
        }
      }
    }
  }

  @content;
}
@mixin promo_slider_adp {
  height: vw-mob(455px);
  margin-bottom: vw-mob(50px);
  .slide_item{
    .bg{
      height: 100%;
      .part{
        &.right{
          height: vw-mob(455px);
        }
      }
    }
    .data{
      .part{
        &.left{
          padding: vw-mob(80px) vw-mob(30px) vw-mob(90px) vw-mob(30px);
          width: 100%;
          &:after{
            content: '';
            position: absolute;
            width: 84vw;
            height: 87vw;
            top: vw-mob(230px);
            left: vw-mob(20px);
            border-radius: vw-mob(20px);
            // background: linear-gradient(180deg, rgba(255, 255, 255, .2) 0%, rgba(255, 255, 255, .6) 100%);
            background: linear-gradient(00deg, rgba(255, 255, 255, .2) 0%, rgba(90, 90, 90, .6) 100%);
          }
          h2.item_title{
            @include vw-font(24, 28, 400);
            margin-bottom: vw-mob(15px);
          }
          p.info{
            @include vw-font(16, 21, 400);
          }
          a.slider{
            padding: 0 vw-mob(20px);
            height: vw-mob(40px);
            @include vw-font(16, 40, 400);
            bottom: vw-mob(120px);
            left: vw-mob(30px);
						// background-color: $RED;
						// color: $WHITE;
          }
          .socials{
            position: absolute;
            top: unset;
            transform: none;
            bottom: vw-mob(70px);
            left: vw-mob(30px);
            height: unset;
            width: vw-mob(140px);
            flex-direction: row;
          }
        }
      }
    }
    &.t2{
      .data{
        .part{
          &.left{
            padding: vw-mob(70px) vw-mob(30px) vw-mob(90px) vw-mob(30px);
            a.slider{
              left: vw-mob(30px);
            }
            .socials{
              left: vw-mob(30px);
              right: unset;
            }
          }
        }
      }
    }
  }
  @content;
}

@mixin agrocoach_block {
  background-color: $FULL_BLACK;
  padding: 100px 40px;
  // margin-bottom: 100px;
  color: $WHITE;
  display: flex;
  justify-content: space-between;
  h2.item_title{
    @include font(48, 50, 400);
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  p.key{
    @include font(20, 26, 400);
  }
	.key {
		p, li {
			@include font(20, 26, 400);
		}
		ul {
			margin-top: 10px;
			padding-left: 25px;
			li {
				@include font(18, 20, 400);
				list-style: disc;
				margin-bottom: 10px;
			}
		} 
	}
	
  .side{
    flex-shrink: 0;
    &.left{
      max-width: 400px;
    }
    &.right{
      width: 870px;
      height: 580px;
      .poster{
        width: 100%;
        height: 100%;
        background-color: var(--bg-color);
        background-image: var(--bg-img);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  @content;
}
@mixin agrocoach_block_adp {
  padding: vw-mob(60px) vw-mob(10px);
  flex-direction: column;
  h2.item_title{
    @include vw-font(24, 32, 400);
    margin-bottom: vw-mob(15px);
  }
  p.key{
    @include vw-font(16, 20, 400);
  }
  .side{
    &.left{
      max-width: unset;
      margin-bottom: vw-mob(40px);
    }
    &.right{
      width: 100%;
      height: vw-mob(200px);
      padding: 0 0 0 vw-mob(40px);
    }
  }
  @content;
}

@mixin values_block {
  padding: 0 20px;
  margin-bottom: 60px;
  .subtitle_wrap{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    h2.item_title{
      @include font(48, 50, 400);
      text-transform: uppercase;
    }
    p.key{
      flex-basis: 50%;
      @include font(20, 26, 400);
    }
  }
  .box_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .box{
      position: relative;
      padding: 60px 40px 0 40px;
      min-height: 400px;
      width: 440px;
      background-color: $BG_GREY;
      margin-bottom: 40px;
      // &:nth-last-child(-n+3){
      // 	margin-bottom: unset;
      // }
      p.title{
        @include font(36, 48, 400);
        margin-bottom: 20px;
      }
      p.key{
        @include font(20, 26, 400)
      }
      &:before{
        content: '';
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        // background-image: var(--w-img);
        background-image: url(../img/4-leaf-rev.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        transition: transform .5s;
      }
      &:hover::before{
        transform: rotate(360deg);
      }
    }
  }

  @content;
}
@mixin values_block_adp {
  padding: 0 vw-mob(10px);
  margin-bottom: vw-mob(50px);
  .subtitle_wrap{
    flex-direction: column;
    margin-bottom: vw-mob(30px);
    h2.item_title{
      @include vw-font(24, 32, 400);
      margin-bottom: vw-mob(15px);
    }
    p.key{
      @include vw-font(16, 20, 400);
    }
  }
  .box_wrap{
    .box{
      position: relative;
      padding: vw-mob(40px) vw-mob(10px) vw-mob(10px) vw-mob(10px);
      min-height: vw-mob(320px);
      width: calc(50% - 2.6667vw); // 10px
      margin-bottom: vw-mob(10px);
      // &:nth-last-child(-n+3){
      //   margin-bottom: vw-mob(10px);
      // }
      // &:nth-last-child(-n+2){
      //   margin-bottom: unset;
      // }
      p.title{
        @include vw-font(18, 22, 400);
        margin-bottom: vw-mob(15px);
      }
      p.key{
        @include vw-font(14, 16, 400)
      }
      &:before{
        top: vw-mob(10px);
        right: vw-mob(10px);
        width: vw-mob(20px);
        height: vw-mob(20px);
      }
    }
  }
  @content;
}

@mixin po_block{
  padding: 0 40px;
  margin-bottom: 100px;
  h2.item_title{
    @include font(48, 64, 400);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    color: $RED;
  }
  .key{
    max-width: 880px;
    margin: 0 auto;
    margin-bottom: 80px;
    p {
      font-family: 'FavoritPro';
      @include font(28, 37, 400);
      text-align: center;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: unset;
      }
    }
  }
  .table_wrap{
    display: flex;
    justify-content: space-between;
    min-height: 570px; // YEEET!!!
  }
  .table_sw{
    flex-shrink: 0;
    width: 400px;
    .sw_item{
      cursor: pointer;
      border-bottom: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
      &:first-child{
        border-top: 2px solid rgba($color: $FULL_BLACK, $alpha: .5);
      }
      p.key{
        padding: 30px 0;
        @include font(24, 28, 400);
        line-height: normal;
        margin: unset;
        text-align: unset;
      }
      &.active{
        color: $RED;
        p.key{
          padding-left: 60px;
          position: relative;
          &:before{
            content: '';
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $RED;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
      }
    }
  }
  .table_body{
    flex-shrink: 0;
    width: calc(100% - 480px);
    .body_item{
      border-top: 2px solid $FULL_BLACK;
      border-bottom: 2px solid $FULL_BLACK;
      display: none;
      &.active{
        display: block;
      }
      table {
        border-collapse: collapse;
      }
      tr{
        border-bottom: 2px solid $FULL_BLACK;
        &:last-child{
          border-bottom: unset;
        }
        td,
        th{
          .poster{
            width: 400px;
            height: 240px;
            background-image: var(--bg-img);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        }
        th{
          padding: 20px 0 20px 0;
        }
        td{
          padding: 20px 20px 20px 50px;
          position: relative;
          vertical-align: baseline;
          p.subtitle{
            @include font(28, 32, 400);
            margin-bottom: 10px;
            text-align: unset;
          }
          .key{
            ul {
              padding: 0 0 0 20px;
            }
            p {
              @include font(20, 24, 400);
              text-align: unset;
            }
            li {
              list-style: disc;
              @include font(18, 22, 400);
            }
            margin-bottom: unset;
          }
          a.more{
            color: $RED;
            @include font(20, 26, 400);
            position: absolute;
            bottom: 20px;
            left: 80px;
          }
        }
      }
    }
  }

  @content;
}
@mixin po_block_adp{
  padding: 0 vw-mob(10px);
  margin-bottom: vw-mob(50px);
  h2.item_title{
    @include vw-font(24, 32, 400);
    margin-bottom: vw-mob(20px);
  }
  .key{
    max-width: unset;
    margin-bottom: vw-mob(40px);
    p {
      @include vw-font(18, 22, 400);
      margin-bottom: vw-mob(10px);
      &:last-child{
        margin-bottom: unset;
      }
    }
  }
  .table_wrap{
    flex-direction: column;
    min-height: unset;
  }
  .table_sw{
    width: 100%;
    .sw_item{
      p.key{
        @include vw-font(18, 70, 400);
      }
      &.active{
        p.key{
          padding: 0 0 0 vw-mob(40px);
          position: relative;
          &:before{
            width: vw-mob(20px);
            height: vw-mob(20px);
          }
        }
      }
    }
  }
  .table_body{
    flex-shrink: 0;
    min-height: vw-mob(1000px);
    width: 100%;
    .body_item{
      border-top: 2px solid transparent;
      table {
        width: 100%;
        border-collapse: collapse;
      }
      tr{
        display: flex;
        flex-direction: column;
        td,
        th{
          .poster{
            width: 100%;
            height: vw-mob(240px);
          }
        }
        th{
          padding: vw-mob(10px) 0;
        }
        td{
          padding: vw-mob(10px) vw-mob(10px) vw-mob(30px) vw-mob(10px);
          p.subtitle{
            @include vw-font(18, 22, 400);
            margin-bottom: vw-mob(10px);
          }
          p.key{
            @include vw-font(16, 20, 400);
          }
          a.more{
            @include vw-font(16, 20, 400);
            bottom: vw-mob(10px);
            left: unset;
            right: vw-mob(10px);
          }
        }
      }
    }
  }
  @content;
}

@mixin bio_block {
  padding: 0 40px;
  margin-bottom: 100px;
  .stage_wrap{
    min-height: 205px;
  }
  .alt{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    h2{
      @include font(48, 65, 400);
      text-transform: uppercase;
      color: $RED;
      width: 400px;
    }
    p{
      @include font(20, 26, 400);
      width: calc(100% - 480px);
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: unset;
      }
    }
  }
  .box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    .box_item{
      position: relative;
      width: 400px;
      height: 420px;
      background-image: var(--bio-img);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .alt_box{
        height: 90px;
        width: 100%;
        background-color: $T_BLACK;
        color: $WHITE;
        position: absolute;
        bottom: 0;
        p{
          @include font(20, 90, 400);
          text-transform: uppercase;
          padding: 0 95px 0 20px;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            width: 35px;
            height: 35px;
            background-image: url(../img/icon/ic-promo-arrow_white.svg);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            bottom: 30px;
            right: 30px;
            opacity: 0;
            transition: opacity .25s;
          }
        }
        &:hover{
          p::after{
            opacity: 1;
          }
        }
      }
    }
  }

  @content;
}
@mixin bio_block_adp{
  padding: vw-mob(40px) vw-mob(10px);
  margin-bottom: vw-mob(50px);
  .stage_wrap{
    min-height: vw-mob(260px);
  }
  .alt{
    flex-direction: column;
    margin-bottom: vw-mob(30px);
    h2{
      @include vw-font(24, 32, 400);
      margin-bottom: vw-mob(10px);
      width: 100%;
    }
    p{
      @include vw-font(14, 18, 400);
      width: 100%;
      margin-bottom: vw-mob(20px);
      &:last-child{
        margin-bottom: unset;
      }
    }
  }
  .box{
    flex-direction: column;
    margin-bottom: vw-mob(50px);
    .box_item{
      width: 100%;
      height: vw-mob(360px);
      margin-bottom: vw-mob(10px);
      &:last-child{
        margin-bottom: unset;
      }
      .alt_box{
        height: vw-mob(80px);
        display: flex;
        align-items: center;
        p{
          width: 100%;
          @include vw-font(17, 22, 400);
          padding: 0 vw-mob(50px) 0 vw-mob(15px);
          &::after{
            width: vw-mob(20px);
            height: vw-mob(20px);
            bottom: 0;
            right: vw-mob(15px);
            opacity: 1;
          }
        }
      }
    }
  }
  @content;
}

@mixin chess_wrapper {
  padding: 0 40px;
  .box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
    .text_wrap{
      max-width: calc(40% - 40px);
      h2{
        @include font(40, 46, 400);
        margin-bottom: 60px;
      }
      p{
        @include font(24, 28, 400);
      }
      ul{
        padding: 0 0 0 20px;
        li{
          @include font(24, 28, 400);
          list-style-type: disc;
        }
      }
    }
    .bg_image{
      background-image: var(--bg-img);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 60%;
      height: 500px;
    }
  }
  @content;
}
@mixin chess_wrapper_adp{
  padding: 0 vw-mob(10px);
  .box{
    flex-direction: column;
    margin-bottom: vw-mob(40px);
    &.rev{
      flex-direction: column;
    }
    .text_wrap{
      max-width: 100%;
      margin-bottom: vw-mob(20px);
      flex-basis: auto;
      h2{
        @include vw-font(24, 32, 400);
        margin-bottom: vw-mob(30px);
      }
      p{
        @include vw-font(16, 22, 400);
      }
      ul{
        padding: 0 0 0 vw-mob(20px);
        li{
          @include vw-font(16, 22, 400);
        }
      }
    }
    .bg_image{
      width: 100%;
      height: vw-mob(380px);
    }
    .type_title{
      flex-basis: 100%;
      margin-top: vw-mob(40px);
      h2{
        @include vw-font(24, 32, 400);
      }
    }
  }
  @content;
}