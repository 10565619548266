header.main_header{
	height: vw-mob(60px);
	padding: vw-mob(10px);
	align-items: center;
	.logo_wrap{
		.logo{
			width: vw-mob(120px);
			height: vw-mob(27px);
			margin-right: unset;
			flex-shrink: 0;
			margin-right: vw-mob(10px);
			&.as{
				margin-right: vw-mob(20px);
			}
		}
	}
	.content_wrap{
		width: calc(100% - 35vw);
		.contact_data{
			padding: 0 vw-mob(15px);
			&:before{
				height: va-mob(25px);
			}
			a.tel{
				color: transparent;
				@include vw-font(1, 1, 400);
				position: relative;
				width: vw-mob(22px);
				height: vw-mob(25px);
				display: block;
				&:before{
					content: '';
					position: absolute;
					width: vw-mob(22px);
					height: vw-mob(25px);
					background-image: url(../img/icon/ic-phone.svg);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					top: 0;
					left: 0;
				}
			}
		}
		.search_field{
			input.search{
				display: none;
			}
			.btn.srch{
				width: vw-mob(30px);
				height: vw-mob(30px);
			}
		}
		.lang_toggle{
			padding: 0 vw-mob(15px);
			span{
				@include vw-font(16, 21, 400);
				padding: unset;
				&:before{
					content: none;
				}
			}
		}
	}
	.src_box{
		position: fixed;
		width: 100%;
		right: -100%;
		// right: 0;
		top: vw-mob(60px);
		bottom: 0;
		padding: vw-mob(60px) vw-mob(20px);
		box-shadow: unset;
		.cnt{
			position: relative;
			max-height: calc(100% - 16vw);
			min-height: calc(100% - 16vw);
			overflow-y: auto;
		}
		input.search{
			display: block;
			position: absolute;
			top: vw-mob(20px);
			width: vw-mob(300px);
			height: vw-mob(35px);
			background-color: $WHITE;
			@include vw-font(20, 35, 400);
			text-align: center;
			border: 1px solid transparent;
			transition: width .25s;
			&::placeholder {
				color: $FULL_BLACK;
			}
		}
		a.close{
			top: vw-mob(28px);
			width: vw-mob(20px);
			height: vw-mob(20px);
			right: vw-mob(20px);
			position: absolute;
		}
		a.cnt_box{
			margin-bottom: vw-mov(15px);
			&:last-child{
				margin-bottom: unset;
			}
			p{
				@include vw-font(14, 18, 400);
				&.cnt_head{
					@include vw-font(18, 22, 600);
					margin-bottom: vw-mob(10px);
				}
				&:not(.cnt_head){
					padding: 0 0 0 vw-mob(30px);
					margin-bottom: vw-mob(15px);
					&:last-child{
						margin-bottom: unset
					}
				}

			}
		}
	}
	&.extend{
		.logo_wrap{
			flex-direction: column;
			padding: vw-mob(5px) vw-mob(10px) vw-mob(5px) 0;
		}
		.logo{
			// width: vw-mob(27px);
			// background-size: auto 100%;
			// background-position: left top;
			&.as{
				margin-right: unset;
				margin-bottom: vw-mob(5px);
			}
			&.jd{
				// background-size: auto 100%;
				// display: block;
				margin-right: unset;
				&:before{
					content: none;
					// left: vw-mob(-10px);
				}
			}
		}
		.content_wrap{
			width: calc(100% - 36vw);
		}
	}
}