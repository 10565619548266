.menu{
	// padding: vw-mob(15px) 0;
	padding: 0 0;
	transform: height .25s;
	min-height: vw-mob(50px);
	height: vw-mob(50px);
	overflow: hidden;
	&.active{
		height: vw-mob(350px);
	}
	ul{
		flex-direction: column;
		max-width: unset;
		justify-content: space-between;
		align-items: center;
		position: relative;
		// padding: vw-mob(50px) 0 0 0;
		margin: vw-mob(50px) 0 0 0;;
		&::before{
			content: 'МЕНЮ';
			text-transform: uppercase;
			position: absolute;
			@include vw-font(16, 21, 400);
			top: vw-mob(-35px);
		}
		li{
			margin-right: unset;
			a.menu_item{
				@include vw-font(20, 32, 400);
				text-align: center;
				&.btn_like{
					padding: 0 vw-mob(10px);
				}
				&.extend{
					padding: 0 vw-mob(20px) 0 0;
					&::after{
						width: vw-mob(10px);
						height: vw-mob(7px);
					}
				}
			}
		}
		&.submenu{
			margin-top: unset;
			position: relative;
			width: auto;
			padding: unset;
			border-radius: unset;
			box-shadow: unset;
			z-index: 2;
			opacity: 1;
			background-color: unset;
			align-items: end;
			li{
				padding: 0 vw-mob(20px) 0 0;
				position: relative;
				&:before{
					content: '-';
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%)
				}
				@include vw-font(16, 24, 400);
			}
		}
	}
}