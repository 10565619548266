footer.main_footer{
	position: relative;
	background-color: $BG_BLACK;
	padding: 80px 80px 40px 80px;
	color: $WHITE;
	.to_top{
		position: absolute;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid $WHITE;
		background: url(../img/icon/ic-arrow_white.svg) no-repeat center;
		background-size: 40%;
		bottom: 40px;
		right: 50px;
	}
	.white{
		background-image: url(../img/logo_white.svg);
		margin-bottom: 30px;
	}
	.socials_wrap{
		display: flex;
		justify-content: space-between;
		position: absolute;
		bottom: 80px;
		left: 80px;
		width: 160px;
		position: absolute;
	}
	.info_wrap{
		display: flex;
		justify-content: space-between;
		position: relative;
		.box{
			&.shift{
				padding-top: 110px;
			}
			.logo_text{
				@include font(20, 50, 400);
				text-transform: uppercase;
				margin-bottom: 60px;
			}
			h3.footer_sub{
				@include font(28, 37, 400);
				text-transform: uppercase;
				margin-bottom: 10px;
			}
			ul{
				margin-bottom: 40px;
				li{
					a{
						@include font(16, 21, 400);
						margin-bottom: 5px;
					}
				}
			}
			.contact_data{
				margin-bottom: 60px;
				a.tel{
					@include font(24, 32, 400);
					margin-bottom: 5px;
				}
			}
			.info_data{
				padding-top: 110px;
			}
			.subscribe_data{
				position: relative;
				p.key{
					@include font(20, 26, 400);
					margin-bottom: 20px;
				}
				input{
					border: unset;
					border-bottom: 1px solid $WHITE;
					background-color: transparent;
					width: 400px;
					height: 40px;
					@include font(20, 40, 400);
					color: $WHITE;
				}
				.arrow{
					position: absolute;
					width: 40px;
					height: 40px;
					background-image: url(../img/icon/ic-arrow_white.svg);
					transform: rotate(90deg);
					right: 0;
					bottom: 0;
				}
			}
		}
	}
	&.en{
		.white{
			background-image: url(../img/logo_white_en.png);
		}
	}
}
@include mobile {
	@import "footer_adp";
}