header.main_header{
	background-color: #EFEFEF;
	height: 100px;
	display: flex;
	padding: 25px 40px;
	position: relative;
	overflow: hidden;
	.logo_wrap{
		display: flex;
		align-items: center;
		.logo{
			// display: inline-block;
			&.as{
				background-image: url(../img/logo.svg);
				margin-right: 20px;
			}
			&.jd{
				position: relative;
				display: none;
				margin-right: 20px;
				&:before{
					content: '';
					position: absolute;
					width: 1px;
					height: 27px;
					border-left: 1px solid $LN_GREY;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
				}
				background-image: url(../img/product/jd-page/jd-logo.webp);
			}
		}
	}
	.content_wrap{
		display: flex;
		width: calc(100% - 270px);
		justify-content: space-between;
		align-items: center;
		.contact_data{
			padding: 0 30px;
			position: relative;
			&:before{
				content: '';
				position: absolute;
				width: 1px;
				height: 27px;
				border-left: 1px solid $LN_GREY;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
			}
			a.tel{
				@include font(22, 29, 400);
			}
		}
		.search_field{
			margin-left: auto;
			display: flex;
			input.search{
				width: 120px;
				margin-right: 5px;
				height: 50px;
				background-color: $WHITE;
				@include font(20, 50, 400);
				text-align: center;
				border: 1px solid transparent;
				transition: width .25s;
				&::placeholder {
					color: $FULL_BLACK;
				}
				&:focus{
					width: 300px;
				}
			}
			.btn.srch{
				width: 50px;
				height: 50px;
				background-color: $FULL_BLACK;
				background-image: url(../img/icon/ic-search_white.svg);
				background-size: 50%;
			}
		}
		.lang_toggle{
			display: flex;
			position: relative;
			justify-content: space-between;
			align-items: center;
			padding: 0 20px;
			a{
				color: $T_GREYB3;
				cursor: pointer;
				@include font(20, 26, 400);
				position: relative;
				padding: 0 10px;
				&.active{
					color: $FULL_BLACK;
				}
				&:before{
					content: '';
					position: absolute;
					width: 1px;
					height: 27px;
					border-left: 1px solid $LN_GREY;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
				}
				&:last-child::before{
					content: none;
				}
			}
		}
	}
	&.extend{
		.logo{
			&.as{
				background-image: url(../img/logo_black.svg);
			}
			&.jd{
				display: inline-block;
			}
		}
		.content_wrap{
			width: calc(100% - 560px);
		}
	}
	&.en{
		.logo_wrap .logo.as{
			background-image: url(../img/logo_red_en.png);
		}
		&.extend{
			.logo_wrap .logo.as{
				background-image: url(../img/logo_black_en.png);
			}
		}

	}
	.src_box{
		position: absolute;
		width: 70%;
		right: -100%;
		// right: 20px;
		top: 80px;
		background-color: $BG_WHITE;
		padding: 40px 20px;
		z-index: 2;
		box-shadow: 5px 5px 5px -2px rgba(0,0,0,0.50);
		.cnt{
			position: relative;
			max-height: 500px;
			overflow-y: auto;
		}
		input.search{
			display: none;
		}
		a.close{
			top: 20px;
			width: 20px;
			height: 20px;
			right: 50px;
			position: absolute;
		}
		a.cnt_box{
			margin-bottom: 20px;
			display: block;
			transition: .25s;
			opacity: 1;
			&:hover{
				opacity: .75;
			}
			&:last-child{
				margin-bottom: unset;
			}
			p{
				@include font(18, 22, 400);
				&.cnt_head{
					@include font(24, 28, 600);
					margin-bottom: 10px;
				}
				&:not(.cnt_head){
					padding: 0 0 0 60px;
					margin-bottom: 15px;
					&:last-child{
						margin-bottom: unset
					}
				}

			}
		}
	}
}
@include mobile {
	@import "header_adp";
}